import React from "react";

import "../index.css";

function Topbar() {
    return (
        <div className="page-content bg-white">
            
        </div>
    );
}
export default Topbar;