import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';


// Website Layout
import WebsiteLayout from './WebsiteLayout';
import AuthLayout from './auth/AuthLayout';
import DashboardLayout from './dashboard/DashboardLayout';

// Websites Pages
import Home from './Home';
import About from './About';
import Internship from './Internship';
import Nccc from './Nccc';
import Courses from './Courses';
import Events from './Events';
import Gallery from './Gallery';
import Contact from './Contact';
import Isc2Courses from './Isc2Courses';
import IsacaCourses from './IsacaCourses';
import EcCouncilCourses from './EcCouncilCourses';
import ComptiaCourses from './ComptiaCourses';
import ProfessionalDevCourses from './ProfessionalDevCourses';
import CisspCoursesDetail from './CisspCoursesDetail';
import CapCoursesDetails from './CapCoursesDetails';
import SscpCoursesDetails from './SscpCoursesDetails';
import CisaCoursesDetails from './CisaCoursesDetails';
import CismCoursesDetails from './CismCoursesDetails';
import CriscCoursesDetails from './CriscCoursesDetails';
import CgeitCoursesDetails from './CgeitCoursesDetails';
import CehCoursesDetails from './CehCoursesDetails';
import ChfiCoursesDetails from './ChfiCoursesDetails';
import IncidentCoursesDetails from './IncidentCoursesDetails';
import CsaCoursesDetails from './CsaCoursesDetails';
import CcisoCoursesDetails from './CcisoCoursesDetails';
import EcsaCoursesDetails from './EcsaCoursesDetails';
import SecurityPlusCoursesDetails from './SecurityPlusCoursesDetails';
import CaspCoursesDetails from './CaspCoursesDetails';
import CloudPlusCoursesDetails from './CloudPlusCoursesDetails';
import CyberIntelCoursesDetails from './CyberIntelCoursesDetails';
import GeneralAwarenessCoursesDetails from './GeneralAwarenessCoursesDetails';
import AuditReportCoursesDetails from './AuditReportCoursesDetails';
import FruadExamsCoursesDetails from './FruadExamsCoursesDetails';
import CyberEngineeringCoursesDetails from './CyberEngineeringCoursesDetails';
import CybersecurityWorkshop from './CybersecurityWorkshop';
import CyberCompition from './CyberCompition';



// Auth Layout
import Auth from './auth/Auth';
import AdminLogin from './auth/AdminLogin';

//Dashboard Layout
import Dashboard from './dashboard/Dashboard';

import NCCCDashboard from './dashboard/NCCCDashboard';
import InternshipDashboard from './dashboard/Internship-dashboard';
import MagnetProgram from './dashboard/MagnetProgram';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Navigate } from 'react-router-dom';
import MagnetPayment from './dashboard/MagnetPayments';


export default function App() {
  const isAuthenticated = sessionStorage.getItem('clientId') !== null;
  return (
    <GoogleOAuthProvider clientId="358441596157-gg4le1opcd4mul5i1t5dlhimf31bma56.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<WebsiteLayout />}>
            <Route index element={<Home />} />
            <Route path='about_us' element={<About />} />
            <Route path='internship' element={<Internship />} />
            <Route path='nccc' element={<Nccc />} />
            <Route path='courses' element={<Courses />} />
            <Route path='events' element={<Events />} />
            <Route path='galleries' element={<Gallery />} />
            <Route path='contact_us' element={<Contact />} />
            <Route path='isc2-courses' element={<Isc2Courses />} />
            <Route path='isaca-courses' element={<IsacaCourses />} />
            <Route path='ec-council-courses' element={<EcCouncilCourses />} />
            <Route path='comptia-courses' element={<ComptiaCourses />} />
            <Route path='professional-development-courses' element={<ProfessionalDevCourses />} />
            <Route path='cissp-courses-details' element={<CisspCoursesDetail />} />
            <Route path='cap-courses-details' element={<CapCoursesDetails />} />
            <Route path='sscp-courses-details' element={<SscpCoursesDetails />} />
            <Route path='cisa-courses-details' element={<CisaCoursesDetails />} />
            <Route path='cism-courses-details' element={<CismCoursesDetails />} />
            <Route path='crisc-courses-details' element={<CriscCoursesDetails />} />
            <Route path='cgeit-courses-details' element={<CgeitCoursesDetails />} />
            <Route path='ceh-courses-details' element={<CehCoursesDetails />} />
            <Route path='chfi-courses-details' element={<ChfiCoursesDetails />} />
            <Route path='incident-response-courses-details' element={<IncidentCoursesDetails />} />
            <Route path='csa-courses-details' element={<CsaCoursesDetails />} />
            <Route path='cciso-courses-details' element={<CcisoCoursesDetails />} />
            <Route path='ecsa-courses-details' element={<EcsaCoursesDetails />} />
            <Route path='security-plus-courses-details' element={<SecurityPlusCoursesDetails />} />
            <Route path='casp-courses-details' element={<CaspCoursesDetails />} />
            <Route path='cloud-plus-courses-details' element={<CloudPlusCoursesDetails />} />
            <Route path='cyber-intelligent-courses-details' element={<CyberIntelCoursesDetails />} />
            <Route path='general-awareness-courses-details' element={<GeneralAwarenessCoursesDetails />} />
            <Route path='audit-report-writing-courses-details' element={<AuditReportCoursesDetails />} />
            <Route path='fraud-examination-courses-details' element={<FruadExamsCoursesDetails />} />
            <Route path='cybersecurity-engineering-courses-details' element={<CyberEngineeringCoursesDetails />} />
            <Route path='cybersecurity-workshop' element={<CybersecurityWorkshop />} />
            <Route path='cyber-compition' element={<CyberCompition />} />

          </Route>

          <Route path='/' element={<AuthLayout />}>
            <Route path='auth' element={<Auth />} />
            <Route path='auth/admin' element={<AdminLogin />} />

          </Route>

          <Route path="/" element={<DashboardLayout />}>
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <Dashboard />
              ) : (
                <Navigate to="/auth" replace />
              )
            }
          />
          <Route path="/nccc-dashboard" element={<NCCCDashboard />} />
          <Route path="/internship-dashboard" element={<InternshipDashboard />} />
          <Route path="/magnetProgram-dashboard" element={<MagnetProgram />} />
          <Route path="/magnetPayment-dashboard" element={<MagnetPayment />} />
          {/* Rest of your routes */}
        </Route>

        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

