import React from "react";
import { Link } from "react-router-dom";
import "../index.css";

function Footer() {

    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-py-60">
                            <div className="row">
                                <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <center>
                                        <Link to="#" className="logo-footer">
                                            <img src="../assets/images/logo/logo.png" height="80" alt="" />
                                        </Link>
                                        <h4 className="mt-4 cyberghanaLogoFooterText">CyberGhana</h4>
                                        <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                            <li className="list-inline-item me-1"><Link to="/" className="rounded"><img src="../assets/images/home/icons/socials/facebook.svg" alt="icon" /></Link></li>
                                            <li className="list-inline-item me-1"><Link to="/" className="rounded"><img src="../assets/images/home/icons/socials/twitter.svg" alt="icon" /></Link></li>
                                            <li className="list-inline-item me-1"><Link to="/" className="rounded"><img src="../assets/images/home/icons/socials/linkedin.svg" alt="icon" /></Link></li>
                                        </ul>
                                    </center>

                                </div>

                                <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Usefull Links</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><Link to="/" className="text-foot"> Home</Link></li>
                                        <li><Link to="/about_us" className="text-foot"> About us</Link></li>
                                        <li><Link to="/internship" className="text-foot"> Internship</Link></li>
                                        <li><Link to="/nccc" className="text-foot"> NCCC</Link></li>
                                        <li><Link to="/events" className="text-foot"> Events</Link></li>
                                    </ul>
                                </div>

                                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Courses</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><Link to="/isc2-courses" className="text-foot"> ISC2</Link></li>
                                        <li><Link to="/isaca-courses" className="text-foot"> ISACA</Link></li>
                                        <li><Link to="/professional-development-courses" className="text-foot"> Prefessional Development</Link>
                                        </li>
                                        <li><Link to="/comptia-courses" className="text-foot"> CompTIA</Link></li>
                                        <li><Link to="/ec-council-courses" className="text-foot"> EC-Council</Link></li>
                                    </ul>
                                </div>

                                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Contact Us</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><Link to="#!" class="text-foot"><img src="../assets/images/home/icons/phone.svg" className="contactIcon" alt="icon" />0207646669 / 0548159595</Link></li>
                                        <li><Link to="#!" class="text-foot"><img src="../assets/images/home/icons/mail.svg" className="contactIcon" alt="icon" />info@cyberghana.com</Link></li>
                                        <li><Link to="#!" class="text-foot"><img src="../assets/images/home/icons/map-pin.svg" className="contactIcon" alt="icon" /> (Ghana) Achiaa Hse (Eco-Bank Building, New
                                            Achimota) Accra</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-py-30 footer-bar">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="text-sm-center">
                                <div className="row">
                                    <div className="col-md-6">
                                        <small className="mb-0 floa ">©
                                            2023 CyberGhana CyberLabs.
                                        </small>

                                    </div>

                                    <div className="col-md-6">
                                        <small className="mb-0 floa ">Craft With ❤ By Doltech Ltd.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;