import React from "react";


class Events extends React.Component{
    render() {
        return(
            <div className="App">

            </div>
        );
    }
}
export default Events;