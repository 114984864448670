import React from "react";
import { Link } from "react-router-dom";



class About extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <section className="bg-half-170 d-table w-100 aboutHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle"> We are one of the leaders in Cybersecurity education, <br /> research, and consulting. </h2>
                                    <p className="text-white para-desc mb-0 mx-auto"></p>
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">About us</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </section>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                {/* Second Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-6">
                                <img src="../assets/images/about/about1.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    {/* <div className="d-flex mb-4">
                                        <span className="text-primary h1 mb-0"><span className="counter-value display-1 fw-bold" data-target="15">0</span>+</span>
                                        <span className="h6 align-self-end ms-2">Years <br /> Experience</span>
                                    </div> */}
                                    <div className="section-title">
                                        <h4 className="title mb-4" id="whoWeAreTitle">Who we are ?</h4>
                                        <p className="text-muted">
                                            CyberGhana is a non-profit organization based in Accra, Ghana. It was formed
                                            in 2016 but only got its Ghanaian registration in 2018. To develop cyber and
                                            engineering capability in Ghana, CyberGhana has partnered with various
                                            universities in Ghana as well as foreign engineering bodies in Europe and North
                                            America. CyberGhana offers career and professional programmes to promote
                                            cybersecurity workforce development in Africa
                                        </p>
                                        {/*     <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> */}
                                    </div>
                                    <div className="section-title">
                                        <h5 className=" mb-2">Track Records</h5>
                                        <p className="text-muted">
                                            We build long-term social and economic change for unemployed and underprivileged
                                            youths in Ghana and beyond who are capable of learning technology, cybersecurity,
                                            and software engineering skills. Our programmes assist underserved youth and adults
                                            in developing employable skills in cyber-engineering, software engineering, and
                                            artificial intelligence. <br /><br /> CyberGhana has trained 2200 youths and 1350 professionals
                                            since its inception in 2018. <br /><br /> CyberGhana has created programmes to recognise young people
                                            in Ghana who excel in the field of cybersecurity engineering.
                                        </p>
                                        {/*     <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </div>
                {/* Second Section Ends */}

                {/* Third Section Starts */}
                <div className=" bg-light" id="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <center>
                                    <div className=" mt-0">
                                        <h2 className="title mb-3" id="africanHudNameSize"> African's Hub for Advanced Cybersecurity <br /> Research & Development</h2>
                                        <hr className="africanHudUnderline" />
                                    </div>
                                </center>
                            </div>
                        </div>
                        <br />
                        <div className="row align-items-center" id="counter">

                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    {/* <div className="d-flex mb-4">
                                        <span className="text-primary h1 mb-0"><span className="counter-value display-1 fw-bold" data-target="15">0</span>+</span>
                                        <span className="h6 align-self-end ms-2">Years <br /> Experience</span>
                                    </div> */}
                                    <div className="section-title">
                                        <p className="text-muted" id="africanHudParagraphSize">
                                            We are a leading provider of hands-on cybersecurity education, information assurance training,
                                            and cybersecurity certifications for IT and security professionals. We work hard to provide
                                            students with the best cybersecurity education possible.
                                        </p>
                                        {/*     <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <img src="../assets/images/about/3.jpg" className="img-fluid shadow-md" alt="" />
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Third Section Ends */}

                {/* Fourth Section Starts */}
                <div className=" mt-5 md-5" id="fourthSectionBg">

                    <div class="container pt-5 mb-md-5">
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <div class="features feature-primary text-center">
                                    <div class="image position-relative d-inline-block">
                                        <img src="../assets/images/about/icons/target.svg" width={30} alt="icon" />
                                    </div>

                                    <div class="content mt-4">
                                        <h5>Vision</h5>
                                        <p class="text-muted mb-0">
                                            To act as a global center for professional studies in information
                                            security and assurance, making cybersecurity open to everyone.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                                <div class="features feature-primary text-center">
                                    <div class="image position-relative d-inline-block">
                                        <img src="../assets/images/about/icons/clock.svg" width={30} alt="icon" />
                                    </div>

                                    <div class="content mt-4">
                                        <h5>Mission</h5>
                                        <p class="text-muted mb-0">
                                            To provide specialized training to help companies strengthen their
                                            security posture by providing high-quality cybersecurity education
                                            and training.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                                <div class="features feature-primary text-center">
                                    <div class="image position-relative d-inline-block">
                                        <img src="../assets/images/about/icons/disc.svg" width={30} alt="icon" />
                                    </div>

                                    <div class="content mt-4">
                                        <h5>Core Value</h5>
                                        <p class="text-muted mb-0">
                                            Education, Academic Quality, Customer Focus, Professionalism & Teamwork
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Fourth Section Ends */}

                {/* Firth Section Starts */}
                <div className=" mt-5 md-5 bg-light" id="">
                    <br /><br />
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="section-title text-center mb-4 pb-2">
                                    <h2 class="title mb-4">Consulting & Advisory</h2>
                                    <center><hr className="consultingAdvisoryLine" /></center>
                                    <p class="text-muted  mx-auto mb-0" id="consultingAdvisoryParagraphSize">
                                        With our consulting services, we offer a cost-effective way for businesses to get help and address
                                        cybersecurity issues. <br /> We help government entities fulfil their cybersecurity and cyberinfrastructure
                                        needs, demonstrating the government's dedication to the citizens. <br /> Public and private sector organisations
                                        entrust us with the protection of their vital infrastructure.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                </div>
                {/* Firth Section Ends */}
                <br /><br /><br />
                <div class="container">
                    <div class="rounded bg-secondary bg-gradient p-lg-5 p-4" id="registrationProcess">
                        <div class="row align-items-end">
                            <div class="col-md-8">
                                <div class="section-title text-md-start text-center">
                                    <h4 class="title mb-3 text-white title-dark">Start your registration process today</h4>
                                    <p class="text-white mb-0">
                                        CyberGhana’s internship program was designed for students and new graduates who are interested in 
                                        gaining experiential learning in cybersecurity.  Students are exposed to projects in malware analysis, 
                                        computer forensics, penetration testing, and risk assessments.  Click here to register for the next 
                                        internship program.
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-4 mt-4 mt-sm-0">
                                <div class="text-md-end text-center" >
                                    <Link to="#!" data-bs-toggle="modal" data-bs-target="#login-popup" class="btn btn-light btn-sm" id="registerBtn">Register Now!</Link>
                                </div>
                            </div>


                        </div>
                        {/* <!-- Modal Form Start --> */}
                        <div class="modal fade" id="login-popup" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog  modal-lg modal-dialog-centered">
                                <div class="modal-content rounded shadow border-0">
                                    <div class="modal-body p-2">
                                        <div class="container-fluid px-0">
                                            <div className="row justify-content-center mt-2">
                                                <div className="col-12 text-center">
                                                    <div className="">
                                                        <h5 className=" mb-4">Choose your Program and Register</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mt-4">
                                                    <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar" id="pills-tab" role="tablist">
                                                        <li className="nav-item">
                                                            <Link className="nav-link rounded active" id="dentalcare" data-bs-toggle="pill" to="#dental" role="tab" aria-controls="dental" aria-selected="false">
                                                                <div className="text-start py-1 px-2">
                                                                    <h6 className="mb-0"> Internship Registration</h6>
                                                                </div>
                                                            </Link>
                                                        </li>

                                                        <li className="nav-item mt-2">
                                                            <Link className="nav-link rounded" id="cardeography" data-bs-toggle="pill" to="#cardeo" role="tab" aria-controls="cardeo" aria-selected="false">
                                                                <div className="text-start py-1 px-2">
                                                                    <h6 className="mb-0">NCCC Registration</h6>
                                                                </div>
                                                            </Link>
                                                        </li>

                                                        <li className="nav-item mt-2">
                                                            <Link className="nav-link rounded" id="eyecare" data-bs-toggle="pill" to="#eye" role="tab" aria-controls="eye" aria-selected="false">
                                                                <div className="text-start py-1 px-2">
                                                                    <h6 className="mb-0">Courses Registration</h6>
                                                                </div>
                                                            </Link>
                                                        </li>


                                                    </ul>
                                                </div>

                                                <div className="col-md-8 col-12 mt-4">
                                                    <div className="tab-content" id="pills-tabContent">
                                                        <div className="tab-pane fade show active p-4 rounded shadow" id="dental" role="tabpanel" aria-labelledby="dentalcare">
                                                            <img src="assets/images/medical/dental.jpg" className="img-fluid rounded shadow" alt="" />
                                                            <div className="">
                                                                <h6>Internship Registration Form</h6>
                                                                <hr />
                                                                <div class="custom-form mt-3">
                                                                    <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                                                                        <p id="error-msg" class="mb-0"></p>
                                                                        <div id="simple-msg"></div>
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Full Name <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="name" id="name" type="text" class="form-control ps-2" placeholder="Name :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-md-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Residential Address, City <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="address" id="address" type="text" class="form-control ps-2" placeholder="Residential Address :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">WhatsApp Number <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="WhatsApp_Number" id="WhatsApp_Number" type="number" class="form-control ps-2" placeholder="Number :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Parent WhatsApp Number <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input type="number" name="Parent_WhatsApp_Number" id="Parent_WhatsApp_Number" class="form-control ps-2" placeholder="Number :" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="email" id="email" type="email" class="form-control ps-2" placeholder="Email :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Program <span class="text-danger">*</span></label>
                                                                                    <select class="form-select form-control" aria-label="Default select example">
                                                                                        <option selected disabled>Program Registering</option>
                                                                                        <option value="Internship">Internship</option>
                                                                                        <option value="Training">Training</option>
                                                                                        <option value="High-School-Bootcamp">High School Bootcamp</option>
                                                                                        <option value="High-School-Leavers-Magnet-Bootcamp">High School Leavers Magnet Bootcamp</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="d-grid">
                                                                                    <button type="submit" id="submitBtn" name="send" class="btn">Submit</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="tab-pane fade p-4 rounded shadow" id="cardeo" role="tabpanel" aria-labelledby="cardeography">
                                                            <img src="assets/images/medical/cardio.jpg" className="img-fluid rounded shadow" alt="" />
                                                            <div className="">
                                                                <h6 className="">NCCC Registration Form</h6>
                                                                <hr />
                                                                <div class="custom-form mt-3">
                                                                    <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                                                                        <p id="error-msg" class="mb-0"></p>
                                                                        <div id="simple-msg"></div>
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Full Name <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="name" id="name" type="text" class="form-control ps-2" placeholder="Name :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-md-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Residential Address, City <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="address" id="address" type="text" class="form-control ps-2" placeholder="Residential Address :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">WhatsApp Number <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="WhatsApp_Number" id="WhatsApp_Number" type="number" class="form-control ps-2" placeholder="Number :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Parent WhatsApp Number <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input type="number" name="Parent_WhatsApp_Number" id="Parent_WhatsApp_Number" class="form-control ps-2" placeholder="Number :" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="email" id="email" type="email" class="form-control ps-2" placeholder="Email :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Program <span class="text-danger">*</span></label>
                                                                                    <select class="form-select form-control" aria-label="Default select example">
                                                                                        <option selected disabled>Program Registering</option>
                                                                                        <option value="Internship">Internship</option>
                                                                                        <option value="Training">Training</option>
                                                                                        <option value="High-School-Bootcamp">High School Bootcamp</option>
                                                                                        <option value="High-School-Leavers-Magnet-Bootcamp">High School Leavers Magnet Bootcamp</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="d-grid">
                                                                                    <button type="submit" id="submitBtn" name="send" class="btn">Submit</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="tab-pane fade p-4 rounded shadow" id="eye" role="tabpanel" aria-labelledby="eyecare">
                                                            <img src="assets/images/medical/eyecare.jpg" className="img-fluid rounded shadow" alt="" />
                                                            <div className="">
                                                                <h6 className="">Paid Courses Registration Form</h6>
                                                                <hr />
                                                                <div class="custom-form mt-3">
                                                                    <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                                                                        <p id="error-msg" class="mb-0"></p>
                                                                        <div id="simple-msg"></div>
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Full Name <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="name" id="name" type="text" class="form-control ps-2" placeholder="Name :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-md-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Residential Address, City <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="address" id="address" type="text" class="form-control ps-2" placeholder="Residential Address :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">WhatsApp Number <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="WhatsApp_Number" id="WhatsApp_Number" type="number" class="form-control ps-2" placeholder="Number :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Parent WhatsApp Number <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input type="number" name="Parent_WhatsApp_Number" id="Parent_WhatsApp_Number" class="form-control ps-2" placeholder="Number :" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Email <span class="text-danger">*</span></label>
                                                                                    <div class="form-icon position-relative">
                                                                                        <input name="email" id="email" type="email" class="form-control ps-2" placeholder="Email :" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-6">
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Program <span class="text-danger">*</span></label>
                                                                                    <select class="form-select form-control" aria-label="Default select example">
                                                                                        <option selected disabled>Program Registering</option>
                                                                                        <option value="Internship">Internship</option>
                                                                                        <option value="Training">Training</option>
                                                                                        <option value="High-School-Bootcamp">High School Bootcamp</option>
                                                                                        <option value="High-School-Leavers-Magnet-Bootcamp">High School Leavers Magnet Bootcamp</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="d-grid">
                                                                                    <button type="submit" id="submitBtn" name="send" class="btn">Submit</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal Form End --> */}
                    </div>
                </div>
                <br /><br /><br />
            </div>
        );
    }
}
export default About;