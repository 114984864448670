import React from "react";
import { Outlet } from "react-router-dom";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
// import DashboardFooter from "../components/DashboardFooter";


const DashboardLayout = () => {
    return ( 
        <>
        <Topbar />
        <Sidebar />
        <Outlet />
        </>
     );
};
 
export default DashboardLayout;