import React from "react";
import { Link } from "react-router-dom";


class Contact extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 contactHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle">  </h2>
                                    {/* <p className="text-white para-desc mb-0 mx-auto"> Areas For Specialization</p> */}
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Contact</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                {/* Second Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div class="container pt-5 mb-md-5">
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <div class="features feature-primary text-center">
                                    <div class="image position-relative d-inline-block">
                                        <img src="../assets/images/contact/phone-call.svg" width={30} alt="icon" />
                                    </div>

                                    <div class="content mt-4">
                                        <h5>Phone</h5>
                                        <Link to="tel:+233207646669" class="text-muted">020 764 6669 / 054 815 9595</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                                <div class="features feature-primary text-center">
                                    <div class="image position-relative d-inline-block">
                                        <img src="../assets/images/contact/mail.svg" width={30} alt="icon" />
                                    </div>

                                    <div class="content mt-4">
                                        <h5>Email</h5>
                                        <Link to="mailto:info@myicia.com" class=" text-muted">info@myicia.com</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                                <div class="features feature-primary text-center">
                                    <div class="image position-relative d-inline-block">
                                        <img src="../assets/images/contact/map-pin.svg" width={30} alt="icon" />
                                    </div>

                                    <div class="content mt-4">
                                        <h5>Location</h5>
                                        <p class="text-muted mb-0">
                                            (Ghana) Achiaa Hse (Eco-Bank Building, New Achimota) Accra
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <center> <hr className="hr" /></center>


                    <br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-6">
                                <img src="../assets/images/contact/contact.svg" className="img-fluid" alt="" />
                            </div>

                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <h4 class="card-title">Get In Touch !</h4>
                                    <div class="custom-form mt-3">
                                        <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                                            <p id="error-msg" class="mb-0"></p>
                                            <div id="simple-msg"></div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                                        <div class="form-icon position-relative">
                                                            <input name="name" id="name" type="text" class="form-control ps-2" placeholder="Name :" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                                        <div class="form-icon position-relative">
                                                            <input name="email" id="email" type="email" class="form-control ps-2" placeholder="Email :" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Subject</label>
                                                        <div class="form-icon position-relative">
                                                            <input name="subject" id="subject" class="form-control ps-2" placeholder="subject :" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Phone</label>
                                                        <div class="form-icon position-relative">
                                                            <input name="subject" id="subject" class="form-control ps-2" placeholder="Number :" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="mb-3">
                                                        <label class="form-label">Message <span class="text-danger">*</span></label>
                                                        <div class="form-icon position-relative">
                                                            <textarea name="comments" id="comments" rows="4" class="form-control ps-2" placeholder="Message :"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-grid">
                                                        <button type="submit" id="submitBtn" name="send" class="btn">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br /><br /><br />
                </div>
                {/* Second Section Ends */}
            </div>
        );
    }
}
export default Contact;