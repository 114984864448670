import React from "react";
import { Link } from "react-router-dom";
import "../index.css"

const Sidebar = ()=> {
    const logout = () => {
        sessionStorage.clear();
        window.location.href = '/'
    }
    return (
        <div className="page-wrapper toggled">
            <nav id="sidebar" className="sidebar-wrapper">
                <div className="sidebar-content" data-simplebar id="sidebarContent">
                    <div className="sidebar-brand">
                        <Link to="#!">
                            <img src="../assets/images/logo/logo.png" width={50} className="me-2" alt="" />
                            CyberGhana
                        </Link>

                    </div>

                    <ul className="sidebar-menu">
                        <li className="">
                            <Link to="/dashboard"><i className="ti ti-apps me-2"><i class="uil uil-estate"></i></i>Dashboard</Link>
                        </li>
                        <li className="">
                            <Link to="nccc-dashboard"><i className="ti ti-apps me-2"><i class="uil uil-heart"></i></i>Nccc</Link>
                        </li>
                        <li className="">
                            <Link to="internship-dashboard"><i className="ti ti-apps me-2"><i class="uil uil-heart"></i></i>Internship</Link>
                        </li>

                        <li className="">
                            <Link to="magnetProgram-dashboard"><i className="ti ti-apps me-2"><i class="uil uil-heart"></i></i>MagnetProgram</Link>
                        </li>
                        <li className="">
                            <Link to="magnetPayment-dashboard"><i className="ti ti-apps me-2"><i class="uil uil-heart"></i></i>MagnetPayment</Link>
                        </li>
                    </ul>
                    <a href onClick={logout} class="btn btn-primary mg" id="AuthBtnn"> Logout</a>
                </div>
            </nav>
        </div>
    )
}
export default Sidebar;