import React from "react";
import { Link } from "react-router-dom";


class NCCC extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 ncccHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle"> NCCC Programs At A Glance </h2>
                                    <p className="text-white para-desc mb-0 mx-auto"> Areas For Specialization</p>
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">NCCC</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* First Section Ends */}
                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                {/* Second Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-5">
                                <img src="../assets/images/nccc/1.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>

                            <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">NCCC Bootcamp</h4>
                                        <p className="text-muted">
                                            Cyberghana launched NCCC Bootcamp in 2022. The training is a hands-on and project-based STEM
                                            and cyber program that motivates students to learn advanced concepts while schooling at the
                                            high school level in a competitive environment.
                                        </p>
                                        <p className="text-muted">
                                            The program is intended to develop a cybersecurity workforce among the youths in Ghana and
                                            Africa. It is part of CyberGhana's commitment to promoting cybersecurity engineering education
                                            and helping build Ghana's pool of diverse talent in cybersecurity from the high school level.
                                            Register your students or school for NCCC Bootcamp.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                {/* Second Section Ends */}

                {/* Third Section Starts */}
                <div className=" bg-light" id="">
                    <br /><br />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <center>
                                    <h4 className="mb-2" id="whoWeAreTitle">NCCC Mock Projects</h4>
                                    <p className="text-muted">
                                        To qualify for NCCC zonal competitions, all schools are required to first enroll their students
                                        in NCCC Bootcamp program. To qualify for NCCC zonal competitions, students(school) must submit
                                        at least one of the mock. projects assigned to schools during the boot camp.<br /> Please, follow these
                                        steps when submitting your boot camp projects:
                                    </p>
                                </center>
                            </div>
                        </div>
                        <div className="row align-items-center" id="counter">

                            <div className="col-md-7 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <div className="section-title">

                                            <ul className="list-unstyled text-muted">
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Complete all class-assigned projects</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Complete all mock projects assigned to your school within 14 days from the last day of the boot camp</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Use the boot camp report template CyberGhana gave you during the boot camp. session to create your report.</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Use the reports to practice your presentation skills.</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Record a video of one of your presentation practice sessions</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Email the video and copies of the reports to info@cyberghana.org</li>
                                            </ul>
                                            <p className="text-muted">
                                                Please, note that CyberGhana’s will not follow your school up for the submission of your mock project
                                                work. Only schools that have submitted mock projects will be invited to participate in the zonal competitions.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5">
                                <img src="../assets/images/nccc/2.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </div>
                {/* Third Section Ends */}

                {/* Fourth Section Starts */}
                <div className="">
                    <br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-5">
                                <img src="../assets/images/nccc/3.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>

                            <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">NCCC Zonal Championships</h4>
                                        <p className="text-muted">
                                            The program puts teams of high school students in the position of newly hired cybersecurity professionals performing
                                            cybersecurity operations tasks in a small company. In the rounds of competition, high school teams are given a set of
                                            tools that represent a natural business environment.
                                        </p>
                                        <p className="text-muted">
                                            Teams are tasked with carrying out real consulting projects.
                                            Register your school or students for Zonal competition if your students (school) has completed a bootcamp.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </div>
                {/* Fourth Section Ends */}

                {/* Firth Section Starts */}
                <div className=" bg-light" id="">
                    <br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">

                            <div className="col-md-7">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="" id="whoWeAreTitle">NCCC National Championships</h4>
                                        <p className="text-muted">
                                            The organizers of the National Championship event puts teams of high school students in the position 
                                            of an entry to mid-level  cybersecurity professionals performing cybersecurity operations tasks in a small company.
                                        </p>
                                        <p className="text-muted">  
                                            Teams are tasked with carrying out real consulting projects. Register your school or students for Zonal competition 
                                            if your students (school) has completed a bootcamp.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5">
                                <img src="../assets/images/nccc/4.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </div>
                {/* Firth Section Ends */}

            </div>
        );
    }
}
export default NCCC;