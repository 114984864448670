import React from "react";
import { Link } from "react-router-dom";

class SecurityPlusCoursesDetails extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 security+DetailHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle">Become CompTIA Security+ Certified</h2>
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item"><Link to="/courses">Courses</Link></li>
                                    <li className="breadcrumb-item"><Link to="/comptia-courses">COMPTIA</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">security+</li>
                                </ul>
                            </nav>
                        </div>

                        <ul className="list-unstyled mt-4 mb-0 position-middle-bottom">
                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-clock fs-5 text-white title-dark align-middle"></i>
                                <span className="text-white-50 ms-1">0 Months</span>
                            </li>

                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-signal fs-5 text-white title-dark align-middle"></i>
                                <span className="text-white-50 ms-1">All Levels</span>
                            </li>

                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-book-open fs-5 text-white title-dark align-middle"></i>
                                <span className="text-white-50 ms-1">0 Lessons</span>
                            </li>

                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-book-reader fs-5 text-white title-dark align-middle"></i>
                                <span className="text-white-50 ms-1">0 Students</span>
                            </li>

                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-shopping-cart fs-5 text-white title-dark align-middle"></i>
                                <Link to="/auth" className="text-white">Enroll Now</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                {/* Second Section Starts */}
                <div className="section">
                    <br /><br />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h4 className="title mb-4">Course Overview</h4>
                                    <hr />
                                    <p className="text-muted">
                                        CompTIA® Security+® (Exam SY0-601) is the primary course you will need to take if your job
                                        responsibilities include securing network services, devices, and traffic and your organization
                                        as a whole including the physical security elements and operational security measures.
                                    </p>
                                    <p className="text-muted">
                                        It follows the latest tools and advancements in the industry to make any individual who
                                        enrolls for this certification, well versed with the latest knowledge related to Security+.
                                    </p>
                                    <p className="text-muted">
                                        This certification through Cyberghana covers all the fundamentals and practical aspects of
                                        network security and risk management. This course is a must for individuals that are
                                        looking for a career in cybersecurity.
                                    </p>

                                    <h5 className=" mb-4">Course Audience</h5>
                                    <div className="text-muted">
                                        This course is targeted toward the information technology (IT) professional who has networking
                                        and administrative skills in Windows®-based Transmission Control Protocol/Internet Protocol
                                        (TCP/IP) networks and familiarity with other operating systemsand who wants to further a career
                                        in IT by acquiring a foundational knowledge of security topics; prepare for the CompTIA Security+
                                        Certification examination; or use Security+ is the foundation for advanced security certifications
                                        or career roles.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mt-4 pt-2"><img src="../assets/images/courses/comptia/comptia.jpg" className="img-fluid rounded shadow" alt="" /></div>
                            <div className="col-md-6 mt-4 pt-2">
                                <div className="position-relative">
                                    <img src="../assets/images/courses/comptia/comptia.jpg" className="rounded img-fluid mx-auto d-block" alt="" />
                                    <div className="play-icon">
                                        <Link to="#!" data-type="youtube" data-id="yba7hPeTSjk" className="play-btn lightbox border-0">
                                            <i className="uil uil-play h6 mb-0 text-primary rounded-circle shadow"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-title mt-4 pt-2">
                            <h4 className="title mb-0">Curriculum</h4>
                            <hr />

                            <div className="row">
                                <div className="col-md-6 mt-4 pt-2">
                                    <h5 className="mb-0">Course Structuring</h5>

                                    <div className="table-responsive bg-white shadow rounded mt-4">
                                        <table className="table mb-0 table-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="fw-normal border-bottom text-muted py-4 px-3">Lessons</th>
                                                    <th scope="col" className="fw-normal border-bottom text-muted py-4 px-3 text-end">Time</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 1: Intorduction of Course</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><span className="badge bg-soft-primary">Read Free</span></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 2: Security Fundamentals</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 3: Identifying Security Threats and Vulnerabilities</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 4: Managing Data, Application, and Host Security</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 5: Implementing Network Security</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 6: Implementing Access Control, Authentication, and Account Management</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="col-md-6 mt-4 pt-2">
                                    <h5 className="mb-0">Courses</h5>

                                    <div className="table-responsive bg-white shadow rounded mt-4">
                                        <table className="table mb-0 table-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="fw-normal border-bottom text-muted py-4 px-3" id="lesson">Lessons</th>
                                                    <th scope="col" className="fw-normal border-bottom text-muted py-4 px-3 text-end">Time</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 7: Managing Certificates</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 8: Implementing Compliance and Operational Security</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 9: Risk Management</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 10: Troubleshooting and Managing Security Incidents</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 11: Business Continuity and Disaster Recovery Planning</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />

                            <div className="section-title mt-4 pt-2">
                                <h4 className="title mb-0">Instructor</h4>
                                <hr />
                                <div className="d-md-flex align-items-center mt-4 pt-2">
                                    <img src="../assets/images/courses/isaca/cgeit/instructor.jpg" className="avatar avatar-medium rounded-pill" alt="" />

                                    <div className="ms-md-3 mt-4 mt-sm-0">
                                        <Link to="#!" class="text-dark h5">Intructor's Name</Link>
                                        <p className="text-muted mb-0 mt-2">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                                            a galley of type and scrambled it to make a type specimen book.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="slider-btn" id="EnrollBtn" data-animation="fadeInUp" data-delay=".6s">
                            <Link to="/auth" className="btn btn-light btn-sm me=3" id="EnrollNowBtn">Enroll Now!</Link>
                        </div>
                        <br /><br /><br />


                    </div>
                    {/* Second Sections Ends */}
                </div>
            </div>
        )
    }
}
export default SecurityPlusCoursesDetails;