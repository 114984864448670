import React from "react";
import { Link } from "react-router-dom";


class CyberCompition extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 compititionHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading"><br /><br />
                                    {/* <h2 className="text-white title-dark" id="aboutUsTitle"> Compitition </h2> */}
                                    {/* <p className="text-white para-desc mb-0 mx-auto"> Areas For Specialization</p> */}
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item"><Link to="#!">Events</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Compititions</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
                <br /><br />
                <section class=" d-table w-100">
                    <div class="container">
                        <div class="row mt-5 align-items-center position-relative" id="videoPlay">
                            <div class="col-lg-6">
                                <div class="title-heading text-center text-lg-start">
                                    {/* <span class="badge rounded-lg bg-soft-primary">Compitition</span> */}
                                    <h4 className="title mb-4">National Cyber Rap Competitions</h4>
                                    <p class="para-desc text-muted mx-auto mx-lg-start mb-0">
                                        CyberGhana is committed to enabling the youths to discover their talents in the entertainment
                                        industry. CyberGhana is launching a rap talent show to sensitize the public about cyber threats
                                        and the opportunities in cybersecurity education.
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6 mt-4 pt-2 mt-lg-0 pt-lg-0">
                                <div class="card border-0 shadow rounded ms-lg-4 overflow-hidden">
                                    <div class="d-flex p-2 bg-light justify-content-between align-items-center">
                                        <div>
                                            <Link to="#!" class="text-danger"><i class="uil uil-circle"></i></Link>
                                            <Link to="#!" class="text-warning"><i class="uil uil-circle"></i></Link>
                                            <Link to="#!" class="text-success"><i class="uil uil-circle"></i></Link>
                                        </div>

                                        <small class="fw-bold"><i class="uil uil-circle text-success"></i> CyberGhana</small>
                                    </div>
                                    <div class="bg-light px-2 position-relative">
                                        <video class="w-100 rounded" controls autoplay loop>
                                            <source src="" type="video/mp4" />
                                        </video>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 mt-4 pt-2">
                                <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <Link className="nav-link rounded active" id="dentalcare" data-bs-toggle="pill" to="#dental" role="tab" aria-controls="dental" aria-selected="false">
                                            <div className="text-start py-1 px-2">
                                                <h6 className="mb-0"> The contest will be held in three rounds</h6>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item mt-2">
                                        <Link className="nav-link rounded" id="cardeography" data-bs-toggle="pill" to="#cardeo" role="tab" aria-controls="cardeo" aria-selected="false">
                                            <div className="text-start py-1 px-2">
                                                <h6 className="mb-0">Prizes for Winners</h6>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item mt-2">
                                        <Link className="nav-link rounded" id="eyecare" data-bs-toggle="pill" to="#eye" role="tab" aria-controls="eye" aria-selected="false">
                                            <div className="text-start py-1 px-2">
                                                <h6 className="mb-0">Consolation Prizes for 4to To 8th Contestants.</h6>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item mt-2">
                                        <Link className="nav-link rounded" id="general-serve" data-bs-toggle="pill" to="#general" role="tab" aria-controls="general" aria-selected="false">
                                            <div className="text-start py-1 px-2">
                                                <h6 className="mb-0">Steps to Enter into the Competition</h6>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item mt-2">
                                        <Link className="nav-link rounded" id="medicine" data-bs-toggle="pill" to="#medical" role="tab" aria-controls="medical" aria-selected="false">
                                            <div className="text-start py-1 px-2">
                                                <h6 className="mb-0">Requirements for the Rap Music Video</h6>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item mt-2">
                                        <Link className="nav-link rounded" id="orthopadic" data-bs-toggle="pill" to="#ortho" role="tab" aria-controls="ortho" aria-selected="false">
                                            <div className="text-start py-1 px-2">
                                                <h6 className="mb-0">Important Deadlines and Timelines</h6>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-8 col-12 mt-4 pt-2">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active p-4 rounded shadow" id="dental" role="tabpanel" aria-labelledby="dentalcare">
                                        <img src="assets/images/medical/dental.jpg" className="img-fluid rounded shadow" alt="" />
                                        <div className="mt-4">
                                            <h5>Round 1:</h5>
                                            <p className="text-muted my-3">
                                                Rap about general concepts in the field of cybersecurity (attend one of our zoom meetings or contact
                                                us on watsapp or on Instagram if you need more info. Watsapp number is on our website). (The top 16
                                                Artists move to second round).
                                            </p>
                                            <h5>Round 2:</h5>
                                            <p className="text-muted my-3">
                                                Advice businesses on how they should take cybersecurity seriously (attend our zoom meeting for further
                                                instructions)—the top 8 move to the finals (Round 3).
                                            </p>
                                            <h5>Round 3:</h5>
                                            <p className="text-muted my-3">
                                                Rap about one of the activities of CyberGhana (attend our zoom meeting for further instructions).
                                            </p>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade p-4 rounded shadow" id="cardeo" role="tabpanel" aria-labelledby="cardeography">
                                        <img src="assets/images/medical/cardio.jpg" className="img-fluid rounded shadow" alt="" />
                                        <div className="mt-4">
                                            <h5 className="title">1St Prize</h5>
                                            <p className="text-muted">
                                                1 free recorded song; 1 free recorded video; Ghc.6000.00 Cash Prize
                                            </p>
                                            <ul className="list-unstyled text-muted">
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Live interviews on National TV and Social Media</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Live performances at CyberGhana’s Conferences</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Two months promo on National TV</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>1-year promo on social media</li>
                                            </ul>

                                            <h5 className="title">2nd Prize</h5>
                                            <p className="text-muted">
                                                1 free recorded  song; 1 free recorded video; Ghc.3000.00 Cash Prize
                                            </p>
                                            <ul className="list-unstyled text-muted">
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Live interviews on National TV and Social Media</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Live performances at CyberGhana’s Conferences</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Two months promo on National TV</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>1-year promo on social media</li>
                                            </ul>

                                            <h5 className="title">3rd Prize</h5>
                                            <p className="text-muted">
                                                1 free recorded song; 1 free recorded video; Ghc.2000.00 Cash Prize
                                            </p>
                                            <ul className="list-unstyled text-muted">
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Live interviews on National TV and Social Media</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Live performances at CyberGhana’s Conferences</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Two months promo on National TV</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>1-year promo on social media</li>
                                            </ul>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade p-4 rounded shadow" id="eye" role="tabpanel" aria-labelledby="eyecare">
                                        <img src="assets/images/medical/eyecare.jpg" className="img-fluid rounded shadow" alt="" />
                                        <div className="mt-4">
                                            <h5 className="title">Consolation Prizes for 4to To 8th Contestants</h5>
                                            <ul className="list-unstyled text-muted">
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Live interviews on National TV and Social Media</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Live performances at CyberGhana’s Conferences</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>1-month promo on National TV</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Six months promo on social media</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Ghc. 500 Cash Prize</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade p-4 rounded shadow" id="general" role="tabpanel" aria-labelledby="general-serve">
                                        <img src="assets/images/medical/general.jpg" className="img-fluid rounded shadow" alt="" />
                                        <div className="mt-4">
                                            <p className="text-muted">Request a copy of the recorded music/beat you will be using to create your rap videos through email at cyberghanarap@gmail.com or through Instagram (cyberghana).</p>
                                            <ul className="list-unstyled text-muted">
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Create your original rap video and send it to <b className="me-3"> <i class="uil uil-envelope-check"></i>cyberghanarap@gmail.com</b></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade p-4 rounded shadow" id="medical" role="tabpanel" aria-labelledby="medicine">
                                        <img src="assets/images/medical/medicine.jpg" className="img-fluid rounded shadow" alt="" />
                                        <div className="mt-4">
                                            <h5 className="title">The initial screening will be based on these requirements: </h5>
                                            <hr />

                                            <ul className="list-unstyled text-muted">
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Contestants are required to record a video and submit it to <b className="me-3"> <i class="uil uil-envelope-check"></i>cyberghanarap@gmail.com</b></li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Anybody can enter the Rap Contest if the submitted content is original and complies with basic copyright rules.</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Contestants may enter the competition as a solo, duo, or group.</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>The lyrics and Beats of the contest submission must be original.</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>The Video should be of good quality and must look professional (clear and non-pixelated, with quality audio, civil and polite, free from politics, non-violent, and no insult or personal attacks)</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Those rapping in any language other than English MUST translate the main idea in English on the screen</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade p-4 rounded shadow" id="ortho" role="tabpanel" aria-labelledby="orthopadic">
                                        <img src="assets/images/medical/orthopedic.jpg" className="img-fluid rounded shadow" alt="" />
                                        <div className="mt-4">
                                            <h5 className="title">Orthopadic Service</h5>
                                            <ul className="list-unstyled text-muted">
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Deadline for Submission of Rap Video: 16th June 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Date for Initial Screening: 20th -25th June 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Online Voting for Round 1 contestants Begins: 26th June 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Online Voting for Round 1 Contestants Ends:  1st July 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Announce those who make it to the second round: 1st July 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Submission of Second Round Videos: 7th to 14th July 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Voting on Second round Videos: 16 July to 22 July 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Announce those who make it to the Finals: 25th July 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Submission of Videos for Final Round: 1- 7th August 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Final Voting starts 8th August 2021</li>
                                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Final Voting ends:  15th August 2021</li>
                                            </ul>

                                            <h5 className="title">Where the contest will be hosted</h5>
                                            <p className="text-muted">National television and social media will host the competition</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
            </div>
        )
    }
}
export default CyberCompition;