import React from "react";
import { Link } from "react-router-dom";

class Internship extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 internshipHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle"> CyberGhana’s Internship At A Glance </h2>
                                    <p className="text-white para-desc mb-0 mx-auto">Internship Areas For Specialization</p>
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Internship</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* First Section Ends */}
                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                {/* Second Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <center>
                                    <div className="title">
                                        <h3 className="heading mb-3 text-dark" id="">
                                            Specialize in Malware, Penetration Testing, <br /> Computer Forensics, and Security Assessment
                                        </h3>
<br />
                                        <hr className="" style={{ width: 300 }} />
                                    </div>
                                </center>
                            </div>
                        </div>
                        <br /><br />
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-5">
                                <img src="../assets/images/internship/malwares.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>

                            <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">Malware Analysis</h4>
                                        <p className="text-muted">
                                            This Program introduces the basic concepts of malware, different types of malwares,
                                            and the malware analysis process. Before moving on with practical and hands-on malware
                                            analysis, students will set up their secured lab for malware analysis. <br /><br /> Students will
                                            get familiar with the basic techniques of static and dynamic malware analysis and will
                                            be allowed to practice all the labs. Students will learn to analyze malware and understand
                                            malware anatomy using various tools and techniques. The course has some hands-on sections
                                            that enable students to practice malware analysis in a virtual environment.
                                        </p>
                                        {/*     <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Second Section Ends */}

                {/* Third Section Starts */}
                <div className=" bg-light" id="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">

                            <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">Penetration Testing</h4>
                                        <p className="text-muted">
                                            This program equips learners with the skills needed to perform penetration testing and
                                            vulnerability assessment effectively. The course provides security professionals with
                                            the tactics, techniques, and procedures (TTP) that attackers and penetration testers use. <br /><br />
                                            Participants will have increased knowledge and understanding of the tools used in a
                                            cyber-attack and, in turn, be better able to prepare defenses. The program focuses on the
                                            skills and knowledge needed to conduct and plan vulnerability assessments and penetration
                                            tests and shows how to conduct tests and assessments legally and ethically.
                                        </p>
                                        {/*     <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5">
                                <img src="../assets/images/internship/pen.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Third Section Ends */}

                {/* Fourth Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-5">
                                <img src="../assets/images/internship/risk.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>

                            <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">IT Security Risk Assessment</h4>
                                        <p className="text-muted">
                                            The ability to perform risk management is crucial for organizations required to detect
                                            threats and defend critical systems. There are too many potential vulnerabilities and
                                            threats that could adversely impact the security of the necessary infrastructure. Risk
                                            management should be one of the practices organizations need to use to adequately defend,
                                            protect, and secure critical systems. <br /><br /> In this program, students will learn how to perform
                                            a risk assessment. Students will learn how to match corporate missions to risk management
                                            goals. Students will learn all the requirements needed for practical risk assessment.
                                        </p>
                                        {/*     <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Fourth Section Ends */}

                {/* Firth Section Starts */}
                <div className=" bg-light" id="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">

                            <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">IT Auditing & Compliance</h4>
                                        <p className="text-muted">
                                            Performing IT security audits at the enterprise level can be a daunting task. Security
                                            analysts and IT auditors usually find it challenging to determine which systems to audit
                                            first. This program is created to provide a risk-driven method for tackling the enormous
                                            task of designing an incorporated security assessment program. <br /><br /> After covering various
                                            high-level audit and compliance topics, students will have the opportunity to delve into
                                            the technical skills required for adequately testing security controls. Real-world project
                                            works to provide students with the needed skills to verify these controls. Students will be
                                            able to perform adequate tests security controls in various scenarios.
                                        </p>
                                        {/*     <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5">
                                <img src="../assets/images/internship/auditt.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Firth Section Ends */}

                {/* Sixth Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-5">
                                <img src="../assets/images/internship/cyber.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>

                            <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">Cyber Threat & Open Source Intelligence</h4>
                                        <p className="text-muted">
                                            The open-source intelligence (OSINT) gathering specialty teaches students how to find, collect,
                                            and analyze data from the Internet. <br /><br /> Far from being a beginner class, this course teaches students
                                            the OSINT groundwork to be successful in finding and using online information, reinforced with
                                            over 10 hands-on projects.
                                        </p>
                                        {/*     <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Sixth Section Ends */}
            </div>
        )
    }
}
export default Internship;