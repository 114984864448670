import React from "react";
import { Link } from "react-router-dom";


class Gallery extends React.Component {


    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 galleryHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle">  </h2>
                                    {/* <p className="text-white para-desc mb-0 mx-auto"> Areas For Specialization</p> */}
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                <div className="">
                    <br />
                    <div className="container">
                        <div className="row">
                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-1-1">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/1.1.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-1-1" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow-lg border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/1.1.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-1">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/1.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-1" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/1.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-2">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/2.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-2" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/2.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-3">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/3.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-3" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/3.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-4">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/4.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-4" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/4.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-5">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/5.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-5" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/5.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-6">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/6.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-6" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/6.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-8">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/8.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-8" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/8.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-9">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/9.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-9" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/9.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden" data-bs-toggle="modal" data-bs-target="#gallery-popup-10">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/10.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                  {/* <!-- Lightbox Start --> */}
                                  <div class="modal fade" id="gallery-popup-10" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/10.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden"  data-bs-toggle="modal" data-bs-target="#gallery-popup-11">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/11.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-11" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/11.jpg" class="lightbox card-img-top" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>

                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="card blog blog-primary rounded border-0 overflow-hidden " data-bs-toggle="modal" data-bs-target="#gallery-popup-12">
                                    <div class="position-relative">
                                        <img src="../assets/images/gallery/12.jpg" class="lightbox card-img-top" alt="..." />
                                        <div class="overlay"></div>
                                    </div>
                                    <div class="position-relative">
                                        <div class="shape overflow-hidden text-color-white">
                                            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox Start --> */}
                                <div class="modal fade" id="gallery-popup-12" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog  modal-lg modal-dialog-centered">
                                        <div class="modal-content rounded shadow border-0">
                                            <div class="modal-body p-0">
                                                <div class="container-fluid px-0">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-0">
                                                            {/*  */}
                                                            <img src="../assets/images/gallery/12.jpg" class="img-fluid" alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Lightbox End --> */}
                            </div>



                        </div>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}
export default Gallery;