import React from "react";
import { Link } from "react-router-dom";
import "../index.css";

function Navbar() {

    return (
        <header id="topnav" className="defaultscroll sticky shadow-md topNavbarColor">
            <div className="container">
                {/* Logo container */}
                <Link className="logo" to="Home.html">
                    <img src="./assets/images/logo/logo.png" height="50" className="logo-light-mode" alt="" />
                </Link>
                {/* Logo End */}

                <div className="menu-extras">
                    <div className="menu-item">
                        {/* Mobile menu toggle */}
                        <Link className="navbar-toggle" id="isToggle" onClick="toggleMenu()">
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>

                    </div>
                </div>

                {/* Login button Start */}
                <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item">
                        <Link to="/auth">
                            <div className="btn btn-sm btn-primary" id="navbarSignInBtn">SIGN IN</div>
                        </Link>
                    </li>
                </ul>


                <div id="navigation">
                    <ul className="navigation-menu">
                        <li><Link to="/" className="sub-menu-item">Home</Link></li>
                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/about_us">ABOUT</Link>
                        </li>

                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/internship">INTERNSHIP</Link>
                        </li>

                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/nccc">NCCC</Link>
                        </li>

                        <li className="has-submenu parent-parent-menu-item">
                            <Link to="/courses">COURSES</Link>
                        </li>

                        <li className="has-submenu parent-menu-item">
                            <Link to="/#">EVENTS</Link><span class="menu-arrow ml-2"></span>
                            <ul className="submenu">
                                <li><Link to="/cybersecurity-workshop" className="sub-menu-item">Cybersecurity Workshop</Link></li>
                                {/* <li><Link to="/cyber-compition" className="sub-menu-item">Compititions</Link></li> */}
                            </ul>
                        </li>

                        <li className="has-submenu parent-menu-item">
                            <Link to="/galleries">GALLERY</Link>
                        </li>

                        <li className="has-submenu parent-menu-item">
                            <Link to="/contact_us">CONTACT</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}
export default Navbar;
