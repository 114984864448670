import React from "react";
import { Link } from "react-router-dom";


class CybersecurityWorkshop extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 CoursesHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle"> CyberGhana Cybersecurity Workshop </h2>
                                    {/* <p className="text-white para-desc mb-0 mx-auto"> Areas For Specialization</p> */}
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item"><Link to="#!">Events</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Workshop</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                {/* Second Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-6">
                                <img src="../assets/images/workshop/workshop.svg" className="img-fluid" alt="" />
                            </div>

                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle"> </h4>
                                        <p className="text-muted">
                                            Are you looking for interactive workshops for your employees? Our FREE cyber workshops are
                                            being sponsored by the Lloyd’s Register Foundation in the United Kingdom.
                                        </p>
                                        <p className="text-muted">
                                            CyberGhana offers online and offline workshops to anyone who has a keen interest in cybersecurity.
                                            We have a large network of highly knowledgeable professionals. These professionals impart their
                                            knowledge to anyone interested in it, in these workshops.
                                        </p>
                                        <p className="text-muted">
                                            Cybersecurity is a rapidly growing industry with new changes being made almost every day. Such
                                            workshops are a great opportunity for individuals to learn and stay updated with the latest
                                            advancements in cybersecurity.
                                        </p>
                                        {/* <Link to="/isc2-courses" class="btn shadow-lg mt-3 raise" id="contactUsBtn">See All Courses</Link> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Second Section Ends */}

                {/* Third Section Starts */}
                <div className=" bg-light">
                    <br /><br />
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-4">Call Us</h4>
                                    <p className="text-muted">On any of our numbers to learn more about the ongoing workshops</p>
                                    {/* <center>
                                        <hr className="expolreUnderline" />
                                    </center> */}
                                    <Link to="/contact_us" class="btn shadow-lg mt-3 raise" id="contactUsBtn">Contact Us Now!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </div>
                {/* Third Section Ends */}
            </div>
        )
    }
}
export default CybersecurityWorkshop;