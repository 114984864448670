import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';

/* eslint-disable no-undef */

const MagnetPayment = () => {

    async function deleteRecord(row) {
        try {
            const response = await fetch(`api/magnet_payment/${row.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json', // Adjust this based on your API's requirements
                    // Other headers if needed
                },
            });
    
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Delete Successful!',
                    didClose: () => {
                        window.location.reload();
                    },
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error occurred while trying to delete!',
                    didClose: () => {
                        window.location.reload();
                    },
                });
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while deleting the record.',
            });
        }
    }
    
    const handleDelete = async (row) => {
        try {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!!!',
                text: 'Do you want to proceed to delete!!!',
                didClose: () => {
                    deleteRecord(row)
                },
            });
            
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };


    const columns = [
        {
            name: 'Fullname',
            selector: row => row.fullname,
            sortable: true
        },
        {
            name: 'School Name',
            selector: row => row.school_name,
            sortable: true
        },
        {
            name: 'Selected Venue',
            selector: row => row.selected_venue,
            sortable: true
        },
        {
            name: 'Phone Number',
            selector: row => row.phone_number,
            sortable: true
        },
        {
            name: 'Parent Phone Number',
            selector: row => row.parent_phone_number,
            sortable: true
        },
        {
            name: 'Paid',
            selector: row => row.hasPaid,
            sortable: true,
            cell: (row) => row.hasPaid ? 'Paid' : 'Not Paid',
        },
        {
            name: 'Actions', // Name for the new column
            cell: row => (
                <button disabled
                    onClick={() => handleDelete(row)}
                    style={{ backgroundColor: 'red', color: 'white', border: 'none', cursor: 'pointer' }}
                >
                    X
                </button>


            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ];

    const [data, setData] = useState({});

    useEffect(() => {
        getMagnetRegistration();

    }, []);

    const getMagnetRegistration = async () => {
        try {
            const response = await fetch('api/magnet_payment/');
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    return (
        <div className="App">
            <div className="page-wrapper toggled">

                <main className="page-content bg-light">
                    <div className="top-header">
                        <div className="">
                            <div className="header-bar d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Link to="#" className="logo-icon me-3">
                                        <img src="../assets/images/logo/logo.png" width="50" className="small" alt="" />
                                    </Link>

                                    <div className="search-bar p-0 d-none d-md-block ms-2">
                                        <div id="search" className="menu-search mb-0">
                                            <form role="search" method="get" id="searchform" className="searchform">
                                                <div>
                                                    <input type="text" className="form-control border rounded" name="s" id="s" placeholder="Search Keywords..." />
                                                    <input type="submit" id="searchsubmit" value="Search" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <ul className="list-unstyled mb-0">

                                    <li className="list-inline-item mb-0 ms-1">
                                        <div className="dropdown dropdown-primary">
                                            <button type="button" className="btn btn-soft-light dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src="assets/images/client/05.jpg" className="avatar avatar-ex-small rounded" alt="" /></button>
                                            <div className="dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3" id="styleDrop">
                                                <Link className="dropdown-item text-dark" to="/"><span className="mb-0 d-inline-block me-1"><i class="uil uil-estate"></i></span> Dashboard</Link>
                                                <Link className="dropdown-item text-dark" to="#!"><span className="mb-0 d-inline-block me-1"><i class="uil uil-sign-out-alt"></i></span> Logout</Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <h5 className="mb-0">Dashboard</h5>

                                </div>
                            </div>
                            <hr />
                            <DataTable
                                title="Magnet Payment Data"
                                columns={columns}
                                data={data}
                                selectableRows
                                pagination
                                paginationServer
                                paginationTotalRows={data.length}
                            />
                        </div>
                    </div>


                    <footer className="shadow py-3">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="text-sm-center text-center mx-md-2">
                                        <p className="mb-0 text-muted">
                                            2023 by CyberGhana CyberLabs.
                                            Powered by Doltech Ltd.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </main>

            </div>
        </div>
    );
}

export default MagnetPayment;