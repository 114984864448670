import React from "react";
import { Link } from "react-router-dom";

class CapCoursesDetails extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 cisspDetailHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle"> Become a Certified Authorization <br /> Professional (CAP) </h2>
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item"><Link to="/courses">Courses</Link></li>
                                    <li className="breadcrumb-item"><Link to="/isc2-courses">ISC2</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">CAP</li>
                                </ul>
                            </nav>
                        </div>

                        <ul className="list-unstyled mt-4 mb-0 position-middle-bottom">
                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-clock fs-5 text-white title-dark align-middle"></i>
                                <span className="text-white-50 ms-1">0 Months</span>
                            </li>

                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-signal fs-5 text-white title-dark align-middle"></i>
                                <span className="text-white-50 ms-1">All Levels</span>
                            </li>

                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-book-open fs-5 text-white title-dark align-middle"></i>
                                <span className="text-white-50 ms-1">0 Lessons</span>
                            </li>

                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-book-reader fs-5 text-white title-dark align-middle"></i>
                                <span className="text-white-50 ms-1">0 Students</span>
                            </li>

                            <li className="align-items-center justify-content-center list-inline-item mx-2">
                                <i className="uil uil-shopping-cart fs-5 text-white title-dark align-middle"></i>
                                <Link to="/auth" className="text-white">Enroll Now</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                {/* Second Section Starts */}
                <div className="section">
                    <br /><br />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h4 className="title mb-4">Course Overview</h4>
                                    <hr />
                                    <p className="text-muted">
                                        If you are looking to build your resume and career by demonstrating your expertise in the field
                                        of information security, then a CAP certification is what you need. It is a certification
                                        established by (ISC) ². It is a highly reliable certification in the field of cybersecurity.
                                    </p>
                                    <p className="text-muted">
                                        It helps individuals prove their knowledge and application to authorize information systems. 
                                        It is believed that this certification is one of the most premium IT certifications.
                                        CyberGhana helps individuals who want to kick start their careers in this field gain a CAP certification.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mt-4 pt-2"><img src="../assets/images/courses/isc2/cap/cap.jpg" className="img-fluid rounded shadow" alt="" /></div>
                            <div className="col-md-6 mt-4 pt-2">
                                <div className="position-relative">
                                    <img src="../assets/images/courses/isc2/cap/cap.jpg" className="rounded img-fluid mx-auto d-block" alt="" />
                                    <div className="play-icon">
                                        <Link to="#!" data-type="youtube" data-id="yba7hPeTSjk" className="play-btn lightbox border-0">
                                            <i className="uil uil-play h6 mb-0 text-primary rounded-circle shadow"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-title mt-4 pt-2">
                            <h4 className="title mb-0">Curriculum</h4>
                            <hr />

                            <div className="row">
                                <div className="col-md-6 mt-4 pt-2">
                                    <h5 className="mb-0">Introductions</h5>

                                    <div className="table-responsive bg-white shadow rounded mt-4">
                                        <table className="table mb-0 table-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="fw-normal border-bottom text-muted py-4 px-3">Lessons</th>
                                                    <th scope="col" className="fw-normal border-bottom text-muted py-4 px-3 text-end">Time</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 1</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><span className="badge bg-soft-primary">Read Free</span></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 2</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 3</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 4</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="col-md-6 mt-4 pt-2">
                                    <h5 className="mb-0">Mastery</h5>

                                    <div className="table-responsive bg-white shadow rounded mt-4">
                                        <table className="table mb-0 table-center">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="fw-normal border-bottom text-muted py-4 px-3" id="lesson">Lessons</th>
                                                    <th scope="col" className="fw-normal border-bottom text-muted py-4 px-3 text-end">Time</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 11</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 12</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 13</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                                <tr>
                                                    <th className="p-3">
                                                        <div className="align-items-center">
                                                            <i className="uil uil-notes h6"></i>
                                                            <p className="mb-0 d-inline fw-normal h6 ms-1"><Link to="#!" className="text-muted">Lesson 14</Link></p>
                                                        </div>
                                                    </th>
                                                    <td className="p-3 text-end"><i className="uil uil-lock"></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />

                            <div className="section-title mt-4 pt-2">
                                <h4 className="title mb-0">Instructor</h4>
                                <hr />
                                <div className="d-md-flex align-items-center mt-4 pt-2">
                                    <img src="../assets/images/courses/isc2/cap/instructor.jpg" className="avatar avatar-medium rounded-pill" alt="" />

                                    <div className="ms-md-3 mt-4 mt-sm-0">
                                        <Link to="#!" class="text-dark h5">Intructor's Name</Link>
                                        <p className="text-muted mb-0 mt-2">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                                            a galley of type and scrambled it to make a type specimen book.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="slider-btn" id="EnrollBtn" data-animation="fadeInUp" data-delay=".6s">
                            <Link to="/auth" className="btn btn-light btn-sm me=3" id="EnrollNowBtn">Enroll Now!</Link>
                        </div>
                        <br /><br /><br />


                    </div>
                    {/* Second Sections Ends */}
                </div>
            </div>
        )
    }
}
export default CapCoursesDetails;