import { React, useState } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';


const Home = () => {

    const initialFormValues = {
        name: '',
        address: '',
        WhatsApp_Number: '',
        number: '',
        email: '',
        school: '',
        parent_number: '',
        Contact_Person: '',
        Contact_Person_number: '',
        selected_program: '',
        selected_magnet_venue: '',
        flexCheckDefault3: '',
        flexCheckDefault4: '',
        flexCheckDefault5: '',
        flexCheckDefault6: '',
        flexCheckDefault2: '',
        flexCheckDefault1: '',
        headmaster_name: '',
        headmaster_address: '',
        headmaster_number: '',
        headmaster_signature: '',
        level: '',
        program: '',
        center: '',
        parent_name: '',
        upload_list_of_students: '',
    }

    const [formValues, setFormValues] = useState(initialFormValues)
    const [isChecked, setIsChecked] = useState(false);

    const handleButtonChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const showSuccessNotification = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${message}`,
            didClose: () => {
                window.location.reload();
            },
        });
    };

    const showPaymentSuccessNotification = (message, response) => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${message}`,
            didClose: () => {
                const url = 'api/internship_registration/';
                const data = {
                    "fullname": formValues.name,
                    "email": formValues.email,
                    "school_name": formValues.school,
                    "center": formValues.center,
                    "payment_token": response,
                    "phone_number": formValues.WhatsApp_Number,
                    "level": formValues.level,
                    "program_of_study": formValues.program
                }

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(() => {
                        setFormValues(initialFormValues);
                        showSuccessNotification("Your magnet registration form has been submitted successfully");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        showErrorNotification(error);
                    });
            }
        });
    };

    const showErrorNotification = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Error occured!',
            text: `${message}`,
            didClose: () => {
                window.location.reload();
            },
        });
    };

    const handleMagnetSubmit = (e) => {
        e.preventDefault();
        const url = 'api/magnet_registration/';
        const data = {
            fullname: formValues.name,
            email: formValues.email,
            address: formValues.address,
            school_name: formValues.school,
            phone_number: formValues.WhatsApp_Number,
            parent_phone_number: formValues.parent_number,
            parent_name: formValues.parent_name
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(() => {
                setFormValues(initialFormValues);
                showSuccessNotification("Your magnet registration form has been submitted successfully");
                // window.location.reload();
            })
            .catch((error) => {
                showErrorNotification(error);
            });
    };

    function concatNonEmptyValues(values) {
        const nonEmptyValues = values.filter(value => value !== '');
        const concatenatedValue = nonEmptyValues.join(',');

        return concatenatedValue;
    }


    const handleNCCCSubmit = (e) => {
        e.preventDefault();
        const url = 'api/program_registration/';

        const fieldValues = [
            formValues.flexCheckDefault1,
            formValues.flexCheckDefault2,
            formValues.flexCheckDefault3,
            formValues.flexCheckDefault4,
            formValues.flexCheckDefault5,
            formValues.flexCheckDefault6
        ];
        const formData = new FormData();
        formData.append("school_name", formValues.name);
        formData.append("address", formValues.address);
        formData.append("contact_person_name", formValues.Contact_Person);
        formData.append("contact_person_email", formValues.email);
        formData.append("contact_person_phone", formValues.Contact_Person_number);
        formData.append("support_with_resource", concatNonEmptyValues(fieldValues));
        formData.append("student_list_file", formValues.upload_list_of_students);
        formData.append("name_of_institution_head", formValues.headmaster_name);
        formData.append("email_of_institution_head", formValues.headmaster_address);
        formData.append("number_of_institution_head", formValues.headmaster_number);
        formData.append("signature_of_institution_head", formValues.headmaster_signature);
        formData.append("program_type", formValues.selected_program);

        fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(() => {
                setFormValues(initialFormValues);
                showSuccessNotification("Your NCCC registration form has been submitted successfully");
            })
            .catch((error) => {
                showErrorNotification(error);
            });
    }


    const handleInternshipSubmit = (e) => {
        e.preventDefault();

        let handler = window.PaystackPop.setup({
            key: 'pk_live_cdeaf25cdb47d2b9eccc1870615d7967d1022177', // Replace with your public key
            email: formValues.email,
            amount: 12000,
            currency: 'GHS',
            ref: '' + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            // label: "Optional string that replaces customer email"
            onClose: function () {
                alert('Window closed.');
            },
            callback: function (response) {
                let message = 'Payment complete! Click ok to complete form submission.\nPayment Reference: ' + response.reference;
                showPaymentSuccessNotification(message, response.reference);
            }
        });

        handler.openIframe();

    }


    const showMagnetPaymentSuccessNotification = (message, response) => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${message}`,
            didClose: () => {
                const url = 'api/magnet_payment/';
                const data = {
                    "fullname": formValues.name,
                    "school_name": formValues.school,
                    "selected_venue": formValues.selected_magnet_venue,
                    "phone_number": formValues.phone_number,
                    "payment_token": response,
                    "parent_phone_number": formValues.WhatsApp_Number
                }

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(() => {
                        setFormValues(initialFormValues);
                        showSuccessNotification("Your magnet payment has been made successfully");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        showErrorNotification(error);
                    });
            }
        });
    };


    const handleMagnetPaymentSubmit = (e) => {
        e.preventDefault();
        var amount_to_pay = 40000; // eslint-disable-next-line
        // if (formValues.selected_magnet_venue == 'Koforidua') {
        //     amount_to_pay = 25000
        // } else amount_to_pay = 24000

        let handler = window.PaystackPop.setup({
            key: 'pk_live_cdeaf25cdb47d2b9eccc1870615d7967d1022177',
            email: formValues.email,
            amount: amount_to_pay,
            currency: 'GHS',
            ref: '' + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            // label: "Optional string that replaces customer email"
            onClose: function () {
                alert('Window closed.');
            },
            callback: function (response) {
                let message = 'Payment complete! Click ok to complete form submission.\nPayment Reference: ' + response.reference;
                showMagnetPaymentSuccessNotification(message, response.reference);
            }
        });

        handler.openIframe();

    }

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            setFormValues({ ...formValues, [name]: files[0] });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    };



    return (
        <div className="App">

            {/* First Section Starts */}
            <div className="bg-half-170 d-table w-100 homeImage" id="home">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-7">
                            <br /><br />
                            <div className="title-heading mt-4">
                                <h1 className="heading mb-3" id="cyberghanaNameSize"> CyberGhana</h1>
                                <p className="para-desc text-white">Practice Hands-On Skills Anywhere. <br />Register for NCCC, NCCC Magnet or Internship</p>

                            </div>
                            <div className="slider-btn" data-animation="fadeInUp" data-delay=".6s">
                                <Link to="#!" data-bs-toggle="modal" data-bs-target="#register" className="btn btn-light btn-sm mg-r-20" id="registerBtn">Register Now!</Link>
                                <Link to="/auth" className="btn btn-light btn-sm" id="SignInBtn">Sign in</Link>
                            </div>

                            {/* <!-- Modal Form Start --> */}
                            <div className="modal fade" id="register" tabindex="-1" aria-hidden="true">
                                <div className="modal-dialog  modal-lg modal-dialog-centered">
                                    <div className="modal-content rounded shadow border-0">
                                        <div className="modal-body p-2">
                                            <div className="container-fluid px-0">
                                                <div className="row justify-content-center mt-2">
                                                    <div className="col-12 text-center">
                                                        <div className="">
                                                            <h5 className=" mb-4">Complete the form below to Register</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 mt-4">
                                                        <ul className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar" id="pills-tab" role="tablist">
                                                            <li className="nav-item">
                                                                <Link className="nav-link rounded active" id="dentalcare" data-bs-toggle="pill" to="#dental" role="tab" aria-controls="dental" aria-selected="false">
                                                                    <div className="text-start py-1 px-2">
                                                                        <h6 className="mb-0">NCCC</h6>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                            {/*<li className="nav-item mt-2">
                                                                <Link className="nav-link rounded" id="cardeography" data-bs-toggle="pill" to="#cardeo" role="tab" aria-controls="cardeo" aria-selected="false">
                                                                    <div className="text-start py-1 px-2">
                                                                        <h6 className="mb-0">Internship</h6>
                                                                    </div>
                                                                </Link>
                                                            </li> */}

                                                            <li className="nav-item mt-2">
                                                                <Link className="nav-link rounded" id="eyecare" data-bs-toggle="pill" to="#eye2" role="tab" aria-controls="eye2" aria-selected="false">
                                                                    <div className="text-start py-1 px-2">
                                                                        <h6 className="mb-0">Magnet Program</h6>
                                                                    </div>
                                                                </Link>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="col-md-8 col-12 mt-4">
                                                        <div className="tab-content" id="pills-tabContent">
                                                            <div className="tab-pane fade show active p-4 rounded shadow" id="dental" role="tabpanel" aria-labelledby="dentalcare">
                                                                <img src="assets/images/medical/dental.jpg" className="img-fluid rounded shadow" alt="" />
                                                                <div className="">
                                                                    <h5 className="text-center">National Cyber Champions Competition</h5>
                                                                    <h6 className="text-center">Event Enrollment Form</h6>
                                                                    <hr />
                                                                    <div className="custom-form mt-3">
                                                                        <form name="myForm" id="myForm" onSubmit={handleNCCCSubmit} encType="multipart/form-data">
                                                                            <p id="error-msg" className="mb-0"></p>
                                                                            <div id="simple-msg"></div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Name of School <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="name" id="name" type="text" className="form-control ps-2" placeholder="Name :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Residential Address <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="address" id="address" type="text" className="form-control ps-2" placeholder="Residential Address :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Email Address <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="email" id="email" type="email" className="form-control ps-2" placeholder="Email :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Contact Person <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="Contact_Person" id="Contact_Person" type="text" className="form-control ps-2" placeholder="Contact Person's Name" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Contact Persons's WhatsApp Number <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} type="tel" name="Contact_Person_number" id="Contact_Person_number" className="form-control ps-2" placeholder="Number :" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>



                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Program <span className="text-danger">*</span></label>
                                                                                        <select onChange={handleChange} className="form-select form-control" aria-label="Default select example" id="selected_program" name="selected_program">
                                                                                            <option value="">Select Program</option>
                                                                                            <option value="NB">NCCC Bootcamp</option>
                                                                                            <option value="NZ">NCCC Zonals</option>
                                                                                            <option value="NGF">NCCC Grand Finale</option>
                                                                                            <option value="CSC">Cybersecurity Short Courses</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Which of the following resources can your school offer for the event? <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <div className="form-check form-check-inline">
                                                                                                <div className="mb-0">
                                                                                                    <div className="form-check">
                                                                                                        <input onChange={handleChange} className="form-check-input" type="checkbox" value="At least one teacher for coodinating activies" id="flexCheckDefault1" name="flexCheckDefault1" />
                                                                                                        <label className="form-check-label" for="flexCheckDefault1">At least one teacher for coodinating activies</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-check">
                                                                                                <div className="mb-0">
                                                                                                    <div className="form-check">
                                                                                                        <input onChange={handleChange} className="form-check-input" type="checkbox" value="At least 3 pieces of computers" id="flexCheckDefault2" name="flexCheckDefault2" />
                                                                                                        <label className="form-check-label" for="flexCheckDefault2">At least 3 pieces of computers</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-check">
                                                                                                <div className="mb-0">
                                                                                                    <div className="form-check">
                                                                                                        <input onChange={handleChange} className="form-check-input" type="checkbox" value="Classroom for learning" id="flexCheckDefault3" name="flexCheckDefault3" />
                                                                                                        <label className="form-check-label" for="flexCheckDefault3">Classroom for learning</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-check">
                                                                                                <div className="mb-0">
                                                                                                    <div className="form-check">
                                                                                                        <input onChange={handleChange} className="form-check-input" type="checkbox" value="Internet" id="flexCheckDefault4" name="flexCheckDefault4" />
                                                                                                        <label className="form-check-label" for="flexCheckDefault4">Internet</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-check">
                                                                                                <div className="mb-0">
                                                                                                    <div className="form-check">
                                                                                                        <input onChange={handleChange} className="form-check-input" type="checkbox" value="A means of transportation for commuting students to and from event sites" id="flexCheckDefault5" name="flexCheckDefault5" />
                                                                                                        <label className="form-check-label" for="flexCheckDefault5">A means of transportation for commuting students to and from event sites</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-check">
                                                                                                <div className="mb-0">
                                                                                                    <div className="form-check">
                                                                                                        <input onChange={handleChange} className="form-check-input" type="checkbox" value="Others" id="flexCheckDefault6" name="flexCheckDefault6" />
                                                                                                        <label className="form-check-label" for="flexCheckDefault6">Others</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Names of Participating Students<span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} type="file" name="upload_list_of_students" id="upload_list_of_students" className="form-control ps-2" placeholder="Upload :" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="mb-3">
                                                                                        <h6 className="text-center fw-bold">Head of Institution's Details</h6>
                                                                                        <hr />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Name Head of Institution <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="headmaster_name" id="headmaster_name" type="text" className="form-control ps-2" placeholder="Name :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Address <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="headmaster_address" id="headmaster_name_address" type="text" className="form-control ps-2" placeholder="Name :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Whatsapp Number <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="headmaster_number" id="Whatsapp_Number" type="tel" className="form-control ps-2" placeholder="Whatsapp Number :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Signature <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="headmaster_signature" id="headmaster_signature" type="file" className="form-control ps-2" placeholder="Name :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />

                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="d-grid">
                                                                                        <button type="submit" id="submitBtn" name="send" className="btn">Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="tab-pane fade p-4 rounded shadow" id="cardeo" role="tabpanel" aria-labelledby="cardeography">
                                                                <img src="assets/images/medical/cardio.jpg" className="img-fluid rounded shadow" alt="" />
                                                                <div className="">
                                                                    <h5 className="text-center">Internship Enrollment Form</h5>
                                                                    <hr />
                                                                    <div className="custom-form mt-3">
                                                                        <form name="myForm" id="myForm" onSubmit={handleInternshipSubmit}>
                                                                            <p id="error-msg" className="mb-0"></p>
                                                                            <div id="simple-msg"></div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Full Name <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="name" id="name" type="text" className="form-control ps-2" placeholder="Name :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Email Address<span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="email" id="email" type="email" className="form-control ps-2" placeholder="Email :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">WhatsApp Number <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="WhatsApp_Number" id="WhatsApp_Number" type="number" className="form-control ps-2" placeholder="Number :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Level <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <select onChange={handleChange} className="form-select form-control" aria-label="Default select example" name="level" id="level">
                                                                                                <option selected disabled>Choose  you level</option>
                                                                                                <option value="100">100</option>
                                                                                                <option value="200">200</option>
                                                                                                <option value="300">300</option>
                                                                                                <option value="400">400</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">School <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="school" id="school" type="text" className="form-control ps-2" placeholder="School :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Program of Study <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="program" id="program" type="text" className="form-control ps-2" placeholder="Program :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Select center you wish to take the internship: <span className="text-danger">*</span></label>
                                                                                        <select onChange={handleChange} className="form-select form-control" aria-label="Default select example" name="center" id="center">
                                                                                            <option selected disabled>Select Center</option>
                                                                                            <option value="Koforidua ">Koforidua</option>
                                                                                            <option value="Accra ">Accra</option>
                                                                                            <option value="Sunyani">Sunyani</option>
                                                                                            <option value="Kumasi">Kumasi</option>
                                                                                            <option value="Kumasi">Wa</option>
                                                                                            <option value="Kumasi">Tamale</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <div className="form-icon position-relative">
                                                                                            <div className="form-check">
                                                                                                <div className="mb-0">
                                                                                                    <div className="form-check">
                                                                                                        <input onChange={handleButtonChange} className="form-check-input" type="checkbox" value="" id="flexCheckDefault5" />
                                                                                                        <p className="form-check-label text-muted" for="flexCheckDefault2">Are you willing to pay an amount of 120 Ghs</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="d-grid">
                                                                                        <button type="submit" disabled={!isChecked} id="submitBtn" name="send" className="btn">Make Payment</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="tab-pane fade p-4 rounded shadow" id="eye" role="tabpanel" aria-labelledby="eyecare">
                                                                <img src="assets/images/medical/eyecare.jpg" className="img-fluid rounded shadow" alt="" />
                                                                <div className="">
                                                                    <h5 className="text-center">National Cyber Champions Competition Magnet Program</h5>
                                                                    <h6 className="text-center">Event Enrollment Form</h6>
                                                                    <hr />
                                                                    <div className="custom-form mt-3">
                                                                        <form name="myForm" id="myForm" onSubmit={handleMagnetSubmit}>
                                                                            <p id="error-msg" className="mb-0"></p>
                                                                            <div id="simple-msg"></div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Full Name <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="name" id="name" type="text" className="form-control ps-2" placeholder="Name :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Residential Address, City <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="address" id="address" type="text" className="form-control ps-2" placeholder="Residential Address :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">WhatsApp Number <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="WhatsApp_Number" id="WhatsApp_Number" type="tel" className="form-control ps-2" placeholder="Number :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Parent WhatsApp Number <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} type="tel" name="parent_number" id="parent_number" className="form-control ps-2" placeholder="Number :" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Email <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="email" id="email" type="email" className="form-control ps-2" placeholder="Email :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Name of High School Attendees <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="school" id="school" type="text" className="form-control ps-2" placeholder="School :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Parents/Gurdian Name <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="parent_name" id="parent_name" type="text" className="form-control ps-2" placeholder="Parent / Gaurdian Name" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="d-grid">
                                                                                        <button type="submit" id="submitBtn" name="send" className="btn">Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade p-4 rounded shadow" id="eye2" role="tabpanel" aria-labelledby="eyecare">
                                                                <img src="assets/images/medical/eyecare.jpg" className="img-fluid rounded shadow" alt="" />
                                                                <div className="">
                                                                    <h5 className="text-center">National Cyber Champions Competition Magnet Program</h5>
                                                                    <h6 className="text-center">Payment Form</h6>
                                                                    <hr />
                                                                    <div className="custom-form mt-3">
                                                                        <form name="myForm" id="myForm" onSubmit={handleMagnetPaymentSubmit}>
                                                                            <p id="error-msg" className="mb-0"></p>
                                                                            <div id="simple-msg"></div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Full Name <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="name" id="name" type="text" className="form-control ps-2" placeholder="Name :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Email <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="email" id="email" type="email" className="form-control ps-2" placeholder="Email :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">WhatsApp Number <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="phone_number" id="phone_number" type="tel" className="form-control ps-2" placeholder="Number :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Parent WhatsApp Number <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} type="tel" name="WhatsApp_Number" id="WhatsApp_Number" className="form-control ps-2" placeholder="Number :" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Name of High School <span className="text-danger">*</span></label>
                                                                                        <div className="form-icon position-relative">
                                                                                            <input onChange={handleChange} name="school" id="school" type="text" className="form-control ps-2" placeholder="School :" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Training Venue <span className="text-danger">*</span></label>
                                                                                        <select onChange={handleChange} className="form-select form-control" aria-label="Default select example" id="selected_magnet_venue" name="selected_magnet_venue">
                                                                                            <option selected value="">Select Venue</option>
                                                                                            <option value="Kumasi">Accra (Food / Hostel - GHS 400.00)</option>
                                                                                            <option value="Kumasi">Kumasi (Food / Hostel - GHS 400.00)</option>
                                                                                            <option value="Koforidua">Koforidua (Food / Hostel - GHS 400.00)</option>
                                                                                            <option value="Kumasi">Tamale (Food / Hostel - GHS 400.00)</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>

                                                                                {/* <div className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <div className="form-icon position-relative">
                                                                                            <div className="form-check">
                                                                                                <div className="mb-0">
                                                                                                    <div className="form-check">
                                                                                                        <input onChange={handleButtonChange} className="form-check-input" type="checkbox" value="" id="flexCheckDefault5" />
                                                                                                        <p className="form-check-label text-muted" for="flexCheckDefault2">Are you willing to pay the specified in the venue you selected?</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="d-grid">
                                                                                        <button type="submit" id="submitBtn" name="send" className="btn">Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Modal Form End --> */}
                        </div>

                        <div className="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <br />
                            <iframe width="550" height="400" src="https://www.youtube.com/embed/Iuoe1o7o-f4" title="CYBERCHAMPIONS BOOTCAMPS &amp; COMPETITONS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {/* First Section Ends */}

            {/* Second Section Starts */}
            <div className="py-4 border-bottom border-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-3 col-12 text-center py-4">
                            <img src="../assets/images/home/icons/globe.svg" className="icons" alt="icon" />
                            <h5 className="pt-3">3</h5>
                            <h5>Countries</h5>
                        </div>

                        <div className="col-lg-3 col-md-3 col-12 text-center py-4">
                            <img src="../assets/images/home/icons/users.svg" className="icons" alt="icon" />
                            <h5 className="pt-3">1300</h5>
                            <h5>Trained Professionals</h5>
                        </div>

                        <div className="col-lg-3 col-md-3 col-12 text-center py-4">
                            <img src="../assets/images/home/icons/settings.svg" className="icons" alt="icon" />
                            <h5 className="pt-3">150</h5>
                            <h5>Tools & Technologies</h5>
                        </div>

                        <div className="col-lg-3 col-md-3 col-12 text-center py-4">
                            <img src="../assets/images/home/icons/monitor.svg" className="icons" alt="icon" />
                            <h5 className="pt-3">150</h5>
                            <h5>Expertise Courses</h5>
                        </div>
                    </div>
                </div>
            </div>
            {/* Second Section Ends */}

            {/* Third Section Starts */}
            <div className=" section  w-100" id="accessiblePlateformBg">
                <div className="container">

                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <center>
                                <div className="title-heading mt-4">
                                    <h1 className="heading mb-3 text-white" id="accessibleNameSize"> Accessbile Plateform</h1>
                                    <hr className="accessbileUnderline" />
                                </div>
                            </center>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div
                                className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
                                <div className="icon text-center rounded-circle me-3">
                                    <img src="../assets/images/home/icons/airplay.svg" className="" alt="icon" />
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">Learn from Anywhere</h4>
                                    <hr className="learnAnywhereLine" />
                                    <p className="">Our cyber labs can be accessed from home or school</p>
                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div
                                className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
                                <div className="icon text-center rounded-circle me-3">
                                    <img src="../assets/images/home/icons/book-open.svg" className="" alt="icon" />
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">Hands-on Learning</h4>
                                    <hr className="HandsonLine" />
                                    <p>Students can practice what they’ve learned in hands-on laboratory exercises</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div
                                className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
                                <div className="icon text-center rounded-circle me-3">
                                    <img src="../assets/images/home/icons/smile.svg" className="" alt="icon" />
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">Easy to Use</h4>
                                    <hr className="EasyUseLine" />
                                    <p>We have an easy to use user interface. Labs are easily assessed from all device</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            {/* Third Section Ends */}

            {/* Fourth Section Starts */}

            <div className="">
                <br /><br />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title mb-4" id="internshipNameSize">CyberGhana’s Internship At A Glance</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Internship Areas For Specialization</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div className="card blog blog-primary rounded border-0 shadow overflow-hidden">
                                <div className="position-relative">
                                    <img src="../assets/images/internship/cyber.jpg" className="card-img-top" alt="..." />
                                    <div className="overlay"></div>
                                </div>
                                <div className="position-relative">
                                    <div className="shape overflow-hidden text-color-white">
                                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-body content">
                                    <Link to="/internship" className="title text-dark h5">Threat Intelligence</Link>
                                    <p className="text-muted mt-2">Cyber intelligence analysts understand not only security
                                        strengths and weaknesses, but the behavioural trends ...</p>
                                    <center>
                                        <Link to="/internship" className="btn btn-sm readMore">Read More <i
                                            className="uil uil-angle-right-b align-middle"></i></Link>
                                    </center>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div className="card blog blog-primary rounded border-0 shadow overflow-hidden">
                                <div className="position-relative">
                                    <img src="../assets/images/internship/risk.jpg" className="card-img-top" alt="..." />
                                    <div className="overlay"></div>
                                </div>
                                <div className="position-relative">
                                    <div className="shape overflow-hidden text-color-white">
                                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-body content">
                                    <Link to="/internship" className="title text-dark h5">Risk Management</Link>
                                    <p className="text-muted mt-2">Keeping vital business data safe and secure is one of the biggest
                                        IT challenges any organization faces.</p>
                                    <center>
                                        <Link to="/internship" className="btn btn-sm readMore">Read More <i
                                            className="uil uil-angle-right-b align-middle"></i></Link>
                                    </center>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div className="card blog blog-primary rounded border-0 shadow overflow-hidden">
                                <div className="position-relative">
                                    <img src="../assets/images/internship/malwares.jpg" className="card-img-top" alt="..." />
                                    <div className="overlay"></div>
                                </div>
                                <div className="position-relative">
                                    <div className="shape overflow-hidden text-color-white">
                                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-body content">
                                    <Link to="/internship" className="title text-dark h5">Malware Analysis</Link>
                                    <p className="text-muted mt-2">This courses helps students to learn how to break down potential
                                        malware threats, create solutions to combat ...</p>
                                    <center>
                                        <Link to="/internship" className="btn btn-sm readMore">Read More <i
                                            className="uil uil-angle-right-b align-middle"></i></Link>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </div>
            {/* Fourth Section Ends */}

            {/* Firth Section Starts */}

            <div className=" bg-light" id="">
                <br /><br />
                <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title mb-4" id="internshipNameSize">NCCC Program At A Glance</h4>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div className="card blog blog-primary rounded border-0 shadow overflow-hidden">
                                <div className="position-relative">
                                    <img src="../assets/images/nccc/1.jpg" className="card-img-top" alt="..." />
                                    <div className="overlay"></div>
                                </div>
                                <div className="position-relative">
                                    <div className="shape overflow-hidden text-color-white">
                                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-body content">
                                    <Link to="/nccc" className="title text-dark h5">NCCC Bootcamp</Link>
                                    <p className="text-muted mt-2">CYBERGHANA launched NCCC Bootcamp in 2022. The training is a hands-on and project-based STEM and
                                        cyber program...</p>
                                    <center>
                                        <Link to="/nccc" className="btn btn-sm readMore">Read More <i
                                            className="uil uil-angle-right-b align-middle"></i></Link>
                                    </center>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div className="card blog blog-primary rounded border-0 shadow overflow-hidden">
                                <div className="position-relative">
                                    <img src="../assets/images/nccc/2.jpg" className="card-img-top" alt="..." />
                                    <div className="overlay"></div>
                                </div>
                                <div className="position-relative">
                                    <div className="shape overflow-hidden text-color-white">
                                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-body content">
                                    <Link to="/nccc" className="title text-dark h5">NCCC Mock Projects</Link>
                                    <p className="text-muted mt-2">To qualify for NCCC zonal competitions, all schools are required to first enroll their students in NCCC
                                        Bootcamp program...</p>
                                    <center>
                                        <Link to="/nccc" className="btn btn-sm readMore">Read More <i
                                            className="uil uil-angle-right-b align-middle"></i></Link>
                                    </center>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                            <div className="card blog blog-primary rounded border-0 shadow overflow-hidden">
                                <div className="position-relative">
                                    <img src="../assets/images/nccc/3.jpg" className="card-img-top" alt="..." />
                                    <div className="overlay"></div>
                                </div>
                                <div className="position-relative">
                                    <div className="shape overflow-hidden text-color-white">
                                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-body content">
                                    <Link to="/internship" className="title text-dark h5">NCCC Zonal Championships</Link>
                                    <p className="text-muted mt-2">The program puts teams of high school students in the position of newly hired cybersecurity
                                        professionals performing...</p>
                                    <center>
                                        <Link to="/internship" className="btn btn-sm readMore">Read More <i
                                            className="uil uil-angle-right-b align-middle"></i></Link>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br />
            </div>
            {/* Firth Section Ends */}

            {/* Sixth Section Starts */}
            <div className=" w-100 " id="home">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-7">
                            <br /><br />
                            <div className="title-heading mt-4">
                                <h6><b>Training Seminars</b></h6>
                                <h1 className="heading mb-3" id="cyberghanaTrainingNameSize"> NCCC Online Safety Campaign</h1>
                                <p className="para-desc ">Become a professional and stay up to date with our cool cyber security day
                                </p>

                            </div>
                            <div className="slider-btn" data-animation="fadeInUp" data-delay=".6s">
                                <Link to="/contact_us" className="btn btn-light btn-sm mg-r-20" id="contactUsBtn">Contact Us
                                    Now!</Link>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="position-relative">
                                <br /><br />
                                <img src="../assets/images/home/Svg.svg" className="rounded img-fluid mx-auto d-block" width="350" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br /><br /><br />

            {/* Sixth Section Ends */}
        </div>
    );
}

export default Home;
