import React from "react";
import { Link } from "react-router-dom";



class Dashboard extends React.Component {
    render() {
        return (
            <div className="App">
                <div className="page-wrapper toggled">



                    <main className="page-content bg-light">
                        <div className="top-header">
                            <div className="">
                                <div className="header-bar d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <Link to="#" className="logo-icon me-3">
                                            <img src="../assets/images/logo/logo.png" width="50" className="small" alt="" />
                                        </Link>

                                        <div className="search-bar p-0 d-none d-md-block ms-2">
                                            <div id="search" className="menu-search mb-0">
                                                <form role="search" method="get" id="searchform" className="searchform">
                                                    <div>
                                                        <input type="text" className="form-control border rounded" name="s" id="s" placeholder="Search Keywords..." />
                                                        <input type="submit" id="searchsubmit" value="Search" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="list-unstyled mb-0">

                                        <li className="list-inline-item mb-0 ms-1">
                                            <div className="dropdown dropdown-primary">
                                                <button type="button" className="btn btn-soft-light dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src="assets/images/client/05.jpg" className="avatar avatar-ex-small rounded" alt="" /></button>
                                                <div className="dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3" id="styleDrop">
                                                    <Link className="dropdown-item text-dark" to="/"><span className="mb-0 d-inline-block me-1"><i class="uil uil-estate"></i></span> Dashboard</Link>
                                                    <Link className="dropdown-item text-dark" to="#!"><span className="mb-0 d-inline-block me-1"><i class="uil uil-sign-out-alt"></i></span> Logout</Link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="layout-specing">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <h6 className="text-muted mb-1">Welcome back, Admin!</h6>
                                        <h5 className="mb-0">Dashboard</h5>

                                    </div>
                                </div>
                                <hr />
                                <h1>Dashboards coming soon, kindly check other tabs</h1>
                            </div>
                        </div>


                        <footer className="shadow py-3">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="text-sm-center text-center mx-md-2">
                                            <p className="mb-0 text-muted">
                                                2023 by CyberGhana CyberLabs.
                                                Powered by Doltech Ltd.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </main>

                </div>
            </div>
        );
    }
}
export default Dashboard;