import React from "react";
import { Link } from "react-router-dom";


class Courses extends React.Component {
    render() {
        return (
            <div className="App">
                {/* First Section Starts */}
                <div className="bg-half-170 d-table w-100 CoursesHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle"> CyberGhana Certification Courses </h2>
                                    <p className="text-white para-desc mb-0 mx-auto"> Areas For Specialization</p>
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Courses</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                {/* Second Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">


                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle"> International Information System Security Certification Consortium (ISC2)</h4>
                                        <p className="text-muted">
                                            Find the (ISC)2 certifications that are best for you. Learn which (ISC)2 certifications can help
                                            you develop your career, regardless of whether you are just beginning your cybersecurity career or
                                            are an experienced professional pursuing leadership or a specialized post.<br /><br /> Click below to view the courses.
                                        </p>
                                        <Link to="/isc2-courses" class="btn shadow-lg mt-3 raise" id="contactUsBtn">See All Courses</Link>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <img src="../assets/images/courses/isc2.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Second Section Ends */}

                {/* Third Section Starts */}
                <div className=" bg-light">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-6">
                                <img src="../assets/images/courses/isaca.jpeg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>

                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">Information Systems Audit and Control Association (ISACA)</h4>
                                        <p className="text-muted">
                                            ISACA, a professional membership organization and a leader in the field, is dedicated to advancing
                                            digital trust by enabling IS/IT professionals to enhance their expertise in audit, cybersecurity,
                                            new technology, and other areas..
                                            <br /><br /> Click below to view the courses.
                                        </p>
                                        <Link to="/isaca-courses" class="btn shadow-lg mt-3 raise" id="contactUsBtn">See All Courses</Link>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Third Section Ends */}

                {/* Fourth Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">


                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle"> International Council of E-Commerce Consultants (EC-Council) </h4>
                                        <p className="text-muted">
                                            The International Council of Electronic Commerce Consultants (EC Council) is a membership organization
                                            that certifies a variety of skills related to e-commerce and information security.
                                            <br /><br /> Click below to view the courses.
                                        </p>
                                        <Link to="/ec-council-courses" class="btn shadow-lg mt-3 raise" id="contactUsBtn">See All Courses</Link>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <img src="../assets/images/courses/ec.jpeg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Fourth Section Ends */}

                {/* Firth Section Starts */}
                <div className=" bg-light">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">
                            <div className="col-md-6">
                                <img src="../assets/images/courses/CompTIA.jpeg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>

                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle">Computing Technology Industry Association (CompTIA)</h4>
                                        <p className="text-muted">
                                            CompTIA (The Computing Technology Industry Association) is the leading provider of vendor-neutral IT 
                                            certifications in the world.
                                            <br /><br /> Click below to view the courses.
                                        </p>
                                        <Link to="/comptia-courses" class="btn shadow-lg mt-3 raise" id="contactUsBtn">See All Courses</Link>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Firth Section Ends */}

                {/* Sixth Section Starts */}
                <div className="">
                    <br /><br /><br />
                    <div className="container">
                        <div className="row align-items-center" id="counter">


                            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <h4 className="mb-4" id="whoWeAreTitle"> Professional Development </h4>
                                        <p className="text-muted">
                                            Professional development is gaining new skills through continuing education and career training after
                                            entering the workforce.
                                            <br /><br /> Click below to view the courses.
                                        </p>
                                        <Link to="/professional-development-courses" class="btn shadow-lg mt-3 raise" id="contactUsBtn">See All Courses</Link>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <img src="../assets/images/courses/Prof.jpg" className="img-fluid shadow-md boderRadius" alt="" />
                            </div>
                        </div>
                    </div>
                    <br /><br /><br />
                </div>
                {/* Sixth Section Ends */}
            </div>
        );
    }
}
export default Courses;