import React from "react";
import { Link } from "react-router-dom";

class EcCouncilCourses extends React.Component {
    render() {
        return(
            <div className="App">
                 {/* First Section Starts */}
                 <div className="bg-half-170 d-table w-100 ecCouncilHomeImage">
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading title-heading">
                                    <h2 className="text-white title-dark" id="aboutUsTitle"> EC-Council Courses </h2>
                                    {/* <p className="text-white para-desc mb-0 mx-auto"> Areas For Specialization</p> */}
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">CyberGhana</Link></li>
                                    <li className="breadcrumb-item"><Link to="/courses">Courses</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">EC-Council</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* First Section Ends */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                 {/* Second Section Starts */}
                 <div className="" id="courses">
                    <br /><br />
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-4">Explore Popular Courses</h4>
                                    <center>
                                        <hr className="expolreUnderline" />
                                    </center>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="card blog blog-primary overflow-hidden rounded shadow border-0">
                                    <div className="position-relative d-block overflow-hidden">
                                        <img src="../assets/images/courses/ec/ceh.jpg" className="img-fluid rounded-top mx-auto" alt="" />
                                        <div className="overlay"></div>
                                        <Link to="/ceh-courses-details" className="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i className="uil uil-angle-right-b align-middle"></i></Link>
                                    </div>

                                    <div className="card-body content">
                                        <Link to="/ceh-courses-details" className="title text-dark h5">Certified Ethical Hacker <br /> (CEH)</Link>
                                        <div className="rating mt-2">
                                            <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item">5 Star (0 <i className="uil uil-user text-muted small"></i>)</li>
                                            </ul>
                                        </div>
                                        <div className="fees d-flex justify-content-between">
                                            <ul className="list-unstyled mb-0 numbers">
                                                <li><i className="uil uil-graduation-cap text-muted"></i> <span>0</span> Students</li>
                                                <li><i className="uil uil-notebooks text-muted"></i> <span>0</span> Lession</li>
                                            </ul>
                                            <h4><span className="h6">GH¢</span>0</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="card blog blog-primary overflow-hidden rounded shadow border-0">
                                    <div className="position-relative d-block overflow-hidden">
                                        <img src="../assets/images/courses/ec/chfi.jpg" className="img-fluid rounded-top mx-auto" alt="" />
                                        <div className="overlay"></div>
                                        <Link to="/chfi-courses-details" className="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i className="uil uil-angle-right-b align-middle"></i></Link>
                                    </div>

                                    <div className="card-body content">
                                        <Link to="/chfi-courses-details" className="title text-dark h5">Computer Hacking Forensic Investigator Certification (CHFI)</Link>
                                        <div className="rating mt-2">
                                            <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item">5 Star (0 <i className="uil uil-user text-muted small"></i>)</li>
                                            </ul>
                                        </div>
                                        <div className="fees d-flex justify-content-between">
                                            <ul className="list-unstyled mb-0 numbers">
                                                <li><i className="uil uil-graduation-cap text-muted"></i> <span>0</span> Students</li>
                                                <li><i className="uil uil-notebooks text-muted"></i> <span>0</span> Lession</li>
                                            </ul>
                                            <h4><span className="h6">GH¢</span>0</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="card blog blog-primary overflow-hidden rounded shadow border-0">
                                    <div className="position-relative d-block overflow-hidden">
                                        <img src="../assets/images/courses/ec/incident.jpg" className="img-fluid rounded-top mx-auto" alt="" />
                                        <div className="overlay"></div>
                                        <Link to="/incident-response-courses-details" className="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i className="uil uil-angle-right-b align-middle"></i></Link>
                                    </div>

                                    <div className="card-body content">
                                        <Link to="/incident-response-courses-details" className="title text-dark h5">Incident Response</Link>
                                        <div className="rating mt-2">
                                            <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item">5 Star (0 <i className="uil uil-user text-muted small"></i>)</li>
                                            </ul>
                                        </div>
                                        <div className="fees d-flex justify-content-between">
                                            <ul className="list-unstyled mb-0 numbers">
                                                <li><i className="uil uil-graduation-cap text-muted"></i> <span>0</span> Students</li>
                                                <li><i className="uil uil-notebooks text-muted"></i> <span>0</span> Lession</li>
                                            </ul>
                                            <h4><span className="h6">GH¢</span>0</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="card blog blog-primary overflow-hidden rounded shadow border-0">
                                    <div className="position-relative d-block overflow-hidden">
                                        <img src="../assets/images/courses/ec/csa.jpg" className="img-fluid rounded-top mx-auto" alt="" />
                                        <div className="overlay"></div>
                                        <Link to="/csa-courses-details" className="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i className="uil uil-angle-right-b align-middle"></i></Link>
                                    </div>

                                    <div className="card-body content">
                                        <Link to="/csa-courses-details" className="title text-dark h5">Certified Security Operations Center Analyst <br />(CSA) </Link>
                                        <div className="rating mt-2">
                                            <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item">5 Star (0 <i className="uil uil-user text-muted small"></i>)</li>
                                            </ul>
                                        </div>
                                        <div className="fees d-flex justify-content-between">
                                            <ul className="list-unstyled mb-0 numbers">
                                                <li><i className="uil uil-graduation-cap text-muted"></i> <span>0</span> Students</li>
                                                <li><i className="uil uil-notebooks text-muted"></i> <span>0</span> Lession</li>
                                            </ul>
                                            <h4><span className="h6">GH¢</span>0</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="card blog blog-primary overflow-hidden rounded shadow border-0">
                                    <div className="position-relative d-block overflow-hidden">
                                        <img src="../assets/images/courses/ec/cciso.jpg" className="img-fluid rounded-top mx-auto" alt="" />
                                        <div className="overlay"></div>
                                        <Link to="/cciso-courses-details" className="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i className="uil uil-angle-right-b align-middle"></i></Link>
                                    </div>

                                    <div className="card-body content">
                                        <Link to="/cciso-courses-details" className="title text-dark h5">Certified Chief Information Security Officer Certification (CCISO)</Link>
                                        <div className="rating mt-2">
                                            <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item">5 Star (0 <i className="uil uil-user text-muted small"></i>)</li>
                                            </ul>
                                        </div>
                                        <div className="fees d-flex justify-content-between">
                                            <ul className="list-unstyled mb-0 numbers">
                                                <li><i className="uil uil-graduation-cap text-muted"></i> <span>0</span> Students</li>
                                                <li><i className="uil uil-notebooks text-muted"></i> <span>0</span> Lession</li>
                                            </ul>
                                            <h4><span className="h6">GH¢</span>0</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="card blog blog-primary overflow-hidden rounded shadow border-0">
                                    <div className="position-relative d-block overflow-hidden">
                                        <img src="../assets/images/courses/ec/ecsa.jpg" className="img-fluid rounded-top mx-auto" alt="" />
                                        <div className="overlay"></div>
                                        <Link to="/ecsa-courses-details" className="h6 preview bottom-0 end-0 me-4 mb-3">Preview Now <i className="uil uil-angle-right-b align-middle"></i></Link>
                                    </div>

                                    <div className="card-body content">
                                        <Link to="/ecsa-courses-details" className="title text-dark h5">EC-Council Certified Security Analyst <br /> (ECSA)</Link>
                                        <div className="rating mt-2">
                                            <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item"><i className="uil uil-star h6 mb-0 text-warning"></i></li>
                                                <li className="list-inline-item">5 Star (0 <i className="uil uil-user text-muted small"></i>)</li>
                                            </ul>
                                        </div>
                                        <div className="fees d-flex justify-content-between">
                                            <ul className="list-unstyled mb-0 numbers">
                                                <li><i className="uil uil-graduation-cap text-muted"></i> <span>0</span> Students</li>
                                                <li><i className="uil uil-notebooks text-muted"></i> <span>0</span> Lession</li>
                                            </ul>
                                            <h4><span className="h6">GH¢</span>0</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                </div>
                {/* Second Section Ends */}
            </div>
        );
    }
}
export default EcCouncilCourses;