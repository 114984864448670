import React from "react";
import { Outlet } from "react-router-dom";
import "../index.css"



function AuthLayout() {
    return(
        <>
        <Outlet />
        </>
    )
}
export default AuthLayout;