import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import "../../src/index.css"
import Swal from 'sweetalert2';


const NCCCDashboard = () => {

    async function deleteRecord(row) {
        try {
            const response = await fetch(`api/magnet_registration/${row.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json', // Adjust this based on your API's requirements
                    // Other headers if needed
                },
            });
    
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Delete Successful!',
                    didClose: () => {
                        window.location.reload();
                    },
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error occurred while trying to delete!',
                    didClose: () => {
                        window.location.reload();
                    },
                });
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while deleting the record.',
            });
        }
    }
    
    const handleDelete = async (row) => {
        try {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!!!',
                text: 'Do you want to proceed to delete!!!',
                didClose: () => {
                    deleteRecord(row)
                },
            });
            
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };

    const splitAndJoin = (str) => {
        const items = str.split(',');
        return (
            <ul>
                {items.map((item, index) => (
                    <li key={index}>{item.trim()}</li>
                ))}
            </ul>
        );
    };

    const handleDownloadFile = (fileData, fileName) => {
        if (!fileData) {
          console.error('Invalid file data');
          return;
        }
      
        const separatorIndex = fileData.indexOf(';base64,');
        if (separatorIndex === -1) {
          console.error('Invalid base64 format');
          return;
        }
      
      
        const fileType = fileData.substring(5, separatorIndex);
        const base64Data = fileData.substring(separatorIndex + 8);
      
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
      
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, { type: fileType });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
      };
      
      



    const columns = [
        {
            name: 'Schoool Name',
            selector: row => row.school_name,
            sortable: true
        },
        {
            name: 'Contact Person',
            selector: row => row.contact_person_name,
            sortable: true
        },
        {
            name: 'Contact Person Email',
            selector: row => row.contact_person_email,
            sortable: true
        },
        {
            name: 'Contact Person Phone No.',
            selector: row => row.contact_person_phone,
            sortable: true
        },
        {
            name: 'Support Resources',
            selector: row => row.support_with_resource,
            cell: (row) => (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-${row.name}`}>{splitAndJoin(row.support_with_resource)}</Tooltip>}
                >
                    <span className="truncate pointer c">{row.support_with_resource}</span>
                </OverlayTrigger>
            ),

        },
        {
            name: 'Student List',
            selector: row => row.student_list_file,
            cell: (row) => (
                <a
                  href
                  className='pointer'
                  onClick={() => handleDownloadFile(row.student_list_file, 'student_list_file')}
                >
                  Download
                </a>
              ),
        },
        {
            name: 'Institution Head',
            selector: row => row.name_of_institution_head,
            sortable: true
        },
        {
            name: 'Institution Head Email',
            selector: row => row.email_of_institution_head,
            sortable: true
        },
        {
            name: 'Institution Head Phone No.',
            selector: row => row.number_of_institution_head,
            sortable: true,
        },
        {
            name: 'Institution Head Signature',
            selector: row => row.signature_of_institution_head,
            cell: (row) => (
                <a
                  href
                  className='pointer'
                  onClick={() => handleDownloadFile(row.signature_of_institution_head, 'signature_of_institution_head')}
                >
                  Download
                </a>
              ),
        },
        {
            name: 'Program Type',
            selector: row => row.program_type,
            sortable: true,
        },
        {
            name: 'Actions', // Name for the new column
            cell: row => (
                <button
                    onClick={() => handleDelete(row)}
                    style={{ backgroundColor: 'red', color: 'white', border: 'none', cursor: 'pointer' }}
                >
                    X
                </button>


            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ];

    const [data, setData] = useState({});

    useEffect(() => {
        getInternshipRegistration();

    }, []);

    const getInternshipRegistration = async () => {
        try {
            const response = await fetch('api/program_registration/');
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };


    return (
        <div className="App">
            <div className="page-wrapper toggled">

                <main className="page-content bg-light">
                    <div className="top-header">
                        <div className="">
                            <div className="header-bar d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Link to="#" className="logo-icon me-3">
                                        <img src="../assets/images/logo/logo.png" width="50" className="small" alt="" />
                                    </Link>

                                    <div className="search-bar p-0 d-none d-md-block ms-2">
                                        <div id="search" className="menu-search mb-0">
                                            <form role="search" method="get" id="searchform" className="searchform">
                                                <div>
                                                    <input type="text" className="form-control border rounded" name="s" id="s" placeholder="Search Keywords..." />
                                                    <input type="submit" id="searchsubmit" value="Search" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <ul className="list-unstyled mb-0">

                                    <li className="list-inline-item mb-0 ms-1">
                                        <div className="dropdown dropdown-primary">
                                            <button type="button" className="btn btn-soft-light dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src="assets/images/client/05.jpg" className="avatar avatar-ex-small rounded" alt="" /></button>
                                            <div className="dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3" id="styleDrop">
                                                <Link className="dropdown-item text-dark" to="/"><span className="mb-0 d-inline-block me-1"><i class="uil uil-estate"></i></span> Dashboard</Link>
                                                <Link className="dropdown-item text-dark" to="#!"><span className="mb-0 d-inline-block me-1"><i class="uil uil-sign-out-alt"></i></span> Logout</Link>
                                            </div>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <h5 className="mb-0">Dashboard</h5>

                                </div>
                            </div>
                            <hr />
                            <DataTable
                                title="NCCC Registration Data"
                                columns={columns}
                                data={data}
                                selectableRows
                                pagination
                                paginationServer
                                paginationTotalRows={data.length}
                            />
                        </div>
                    </div>


                    <footer className="shadow py-3">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="text-sm-center text-center mx-md-2">
                                        <p className="mb-0 text-muted">
                                            2023 by CyberGhana CyberLabs.
                                            Powered by Doltech Ltd.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </main>

            </div>
        </div>
    );

}
export default NCCCDashboard;