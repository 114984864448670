import React from "react";
import { Link } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';



const AdminLogin = () => {

    const navigate = useNavigate();

    return (
        <div className="App">
            <div class="back-to-home">
                <Link to="/" class="back-button btn btn-icon btn-primary" id="homeBtn"><img src="../../assets/images/auth/home.svg" width={20} className="icon" alt="" /></Link>
            </div>

            <section class="cover-user">
                <div class="container-fluid px-0">
                    <div class="row g-0 position-relative">
                        <div class="col-lg-4 cover-my-30 order-2">
                            <div class="cover-user-img d-flex align-items-center">
                                <div class="row">
                                    {/* <div className="col-12">
                                            <img src="../../assets/images/auth/logo.png" className="img-fluid" id="topLogo" alt="" />
                                        </div> */}
                                    <div class="col-12">
                                        <div class="card login-page border-0" id="loginPage">
                                            <div class="card-body p-0">
                                                <h4 class="card-title text-left" id="welcomeCyberghana">Welcome to CyberGhana Admin Login</h4>
                                                <hr />
                                                <p className="text-muted">Sign in to access admin dashboard. You can sign in immediately through Google.</p>
                                                <form class="login-form mt-4">
                                                    <div class="row">
                                                        <div class="col-lg-12 mt-4 text-center">
                                                            <div class="row">
                                                                <div class="col-12 mt-3">
                                                                    <div class="d-grid">
                                                                        <GoogleLogin
                                                                            onSuccess={credentialResponse => {
                                                                                sessionStorage.setItem('clientId', credentialResponse.clientId);
                                                                                window.location.href = '/dashboard'
                                                                                
                                                                                // setTimeout(() => {
                                                                                //     navigate('/dashboard');
                                                                                //   }, 2000); 
                                                                            }}
                                                                            onError={() => {
                                                                                alert('Login Failed');
                                                                                navigate('/');
                                                                            }}
                                                                        />;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 offset-lg-4 padding-less img order-1 jarallax" data-jarallax data-speed="0.5" id="authHomeImage"></div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AdminLogin;